import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step'
import { scrollToUnlessVisible } from 'javascripts/consumer/utils/scroll_to'
import PaymentForm, {PAYMENT_INFO_TITLE} from 'javascripts/consumer/components/payment_form'
import Rails from '@rails/ujs'

export default class PaymentStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)

    this.completedSignup = this.container.data('completed')
    this.bind()
  }

  bind() {
    this.container.on('form:submit:error', () => { this.state = 'active' })
  }

  onActivated(stateWas) {
    if (stateWas === 'completed') this.completedSignup = false
    this.initPaymentForm()

    if (!this.completedSignup || stateWas !== 'inactive') {
      return
    }

    setTimeout(() => { scrollToUnlessVisible(this.container) }, 10)
    this.currentState = 'completed'
  }

  afterActivated() {
    $(PAYMENT_INFO_TITLE).focus();
  }

  initPaymentForm() {
    const paymentFormContainer = this.container.find('[data-fragment="gift-steps-payment-form"]')
    if (paymentFormContainer.data('payment-form')) return

    this.container.find('[data-toggle="expandable-popover"]').expandablePopover()

    const paymentForm = new PaymentForm(this.container.closest('.js-payment-container'), null)
    paymentForm.paymentButtons.forEach((paymentButton) => {
      paymentButton.onAuthorize.then(() => {
        let $form = this.container.closest('form');
        if ($form.data('remote')) {
          Rails.fire($form[0], 'submit');
        } else {
          $form.trigger('submit');
        }
      });
    })

    paymentFormContainer.data('payment-form', true)

    return paymentForm;
  }
}
