import JsToggle from 'javascripts/consumer/components/js_toggle'
const FILTER_BY = {
  'not-claimed': ['earned', 'sent'],
  'claimed': ['redeemed', 'consumed'],
  'expired': ['expired']
}

class GiveawaysFilter {
  constructor(filterContainer) {
    this.container = filterContainer;
    this.filterStatus = this.container.find('.js-giveaways__filter-status')
    this.filterToggle = this.container.find('.js-toggle')
    this.bind()
  }

  bind() {
    this.container.on('click', '.js-giveaways__filter-link', this.activateFilter.bind(this))
    $(document).on('click', this.hideToggle.bind(this))
  }

  activateFilter(event) {
    const link = $(event.target)
    event.preventDefault()
    this.container.find('.js-giveaways__filter-link').removeClass('is-active')
    this.filterStatus.text(link.text())
    link.addClass('is-active')

    this.filterGiveaways(link.data('filter'))
  }

  filterGiveaways(filter) {
    const filterBy = FILTER_BY[filter]

    if (!filterBy)  {
      this.container.find('.js-giveaway').removeClass('d-none')
      return
    }

    this.container.find('.js-giveaway').addClass('d-none')

    filterBy.forEach(function(filter) {
      this.container.find(`.js-giveaway--${filter}`).removeClass('d-none');
    }.bind(this));
  }

  hideToggle(event) {
    if (this.filterToggle.has($(event.target)).length > 0) return
    JsToggle.toggle(this.filterToggle, false)
  }
}

$(function() {
  $('.js-giveaways__filter').each(function(index, element) {
    new GiveawaysFilter($(element))
  })
})
