const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

function validationResult(isValid, message) {
  return isValid ? null : message
}

export const VALIDATIONS = {
  PRESENT:  '* This field is required',
  EMAIL:  '* Please enter a valid email address.',
  DATE: '* Please select a valid date',
  MAX_DATE: '* You can not select a date more than 5 months in advance',
  isPresent: function(input) { return validationResult(input.val().length > 0, VALIDATIONS.PRESENT); },

  isValidDate: function(selects) {
    if (this.sendingMethod !== 'email') return null

    const [today, selectedDate, dateValues] = validationDates(selects)

    if(selectedDate.getDate() != dateValues[2] || selectedDate.getMonth() != dateValues[1] || selectedDate.getFullYear() != dateValues[0]) {
      return validationResult(false, VALIDATIONS.DATE)
    }

    return validationResult(today <= selectedDate, VALIDATIONS.DATE)
  },

  isPresentForEmail: function(input) {
    if (this.sendingMethod !== 'email') return null
    return VALIDATIONS.isPresent(input)
  },

  isValidEmail: function(input) {
    return validationResult(!!input.val().match(EMAIL_REGEXP), VALIDATIONS.EMAIL)
  },

  isValidRecipientEmail: function(input) {
    if (this.sendingMethod !== 'email') return null
    return VALIDATIONS.isValidEmail(input)
  },

  maxDate(selects) {
    if (this.sendingMethod !== 'email') return null

    const [today, selectedDate, _] = validationDates(selects)
    const maxDate = new Date(today)

    maxDate.setMonth(today.getMonth() + 6)
    maxDate.setDate(1)

    return validationResult(selectedDate < maxDate, VALIDATIONS.MAX_DATE)
  }
}

export function validationDates(selects) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const [year, month, day] = selects.val().split('-')
  const selectedDate = new Date(year, month - 1, day)

  return [today, selectedDate, [selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]]
}
