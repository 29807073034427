import WebViewMessage from 'models/WebViewMessage';

const postMessage = (
  type: WebViewMessage,
  options: Record<string, unknown> = {},
): void => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...options, type }),
  );
};

const closeWebview = (): void => {
  postMessage(WebViewMessage.WEBVIEW_CLOSE);
};

export { postMessage, closeWebview, WebViewMessage };
