import 'javascripts/consumer/utils/deserialize_form'

export default class ModalNotSaved {
  constructor(container) {
    this.container = container
    this.form = this.container.find('form')

    if (this.form.length === 0) return

    this.initialFormData = this.form.serialize();
    this._persisted = false

    this.bind()
  }

  onNotSaved(callback) {
    this.onNotSaved = callback;
  }

  bind() {
    this.container.on('ajax:success', 'form', () => {
      this._persisted = true;
      this.initialFormData = this.form.serialize()
    });
    this.container.on('hide.bs.modal', this._checkIfNotSaved.bind(this))
    this.container.on('show.bs.modal', () => { this._persisted = false; this.initialFormData = this.form.serialize() })
  }

  _checkIfNotSaved() {
    if (this.container.data('noChanges')) return

    if (this.form.serialize() !== this.initialFormData) {
      this.form[0].reset()
      this.form.deserialize(this.initialFormData)
      this.onNotSaved.apply(this)
    }

    return true;
  }

  set _persisted(value) {
    this.container.data('form-persisted', value)
  }
}
