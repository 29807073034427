import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step'
import InputValidation from 'javascripts/consumer/components/input_validation'
import { VALIDATIONS, validationDates } from './validations'
import { scrollToUnlessVisible } from 'javascripts/consumer/utils/scroll_to'
import escape from 'lodash/escape'

const LOCALE = "en-us";

const safeValue = target => escape(target.input.val());

export default class DetailsStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)
    this.completedContainer = this.container.find('.js-accordion-step__completed-body')
    this.sendingMessageContainer = this.container.find('.js-gifts-flow__sending-message-container')
    this.sendingMethod = 'email'

    this.sendingMessage = this.container.find('.js-gifts-flow__sending-message')
    this.submitButton = this.container.find('.js-accordion-step__complete')

    this.recipientName = new InputValidation($('.js-gifts-flow__recipient-name'), [VALIDATIONS.isPresent])
    this.recipientEmail = new InputValidation($('.js-gifts-flow__recipient-email'), [VALIDATIONS.isPresentForEmail.bind(this), VALIDATIONS.isValidRecipientEmail.bind(this)])
    this.sendingName = new InputValidation($('.js-gifts-flow__sending-name'), [VALIDATIONS.isPresent])
    this.sendingEmail = new InputValidation($('.js-gifts-flow__sending-email'), [VALIDATIONS.isPresent, VALIDATIONS.isValidEmail])
    this.deliveryDate = new InputValidation($('.js-gifts-flow__delivery-date'), [VALIDATIONS.isValidDate.bind(this), VALIDATIONS.maxDate.bind(this)])

    $('[data-toggle="popover"]').popover()

    this.bind()
  }

  bind() {
    this.container.on('change', '.js-gifts-flow__details-sending-method', this.toggleEmailFields.bind(this))
  }

  canComplete() {
    const inputs =  [
      [this.recipientName, this.recipientName.isValid()],
      [this.recipientEmail, this.recipientEmail.isValid()],
      [this.deliveryDate, this.deliveryDate.isValid()],
      [this.sendingName, this.sendingName.isValid()],
      [this.sendingEmail, this.sendingEmail.isValid()]
    ]

    const index = inputs.findIndex(function([input, isValid]) { return !isValid })
    if (index !== -1) scrollToUnlessVisible(inputs[index][0].input.closest('.js-form-group'))

    return index === -1
  }

  onCompleted() {
    this.sendingMessageContainer.html(escape(this.sendingMessage.val()));
    this.container.find('.js-gifts-flow__sending-details-link').toggle(this.sendingMessage.val().trim().length > 0)
    this.completedContainer.html(`
      ${this.deliveryDateValue()}
      <div><strong>To:</strong> ${safeValue(this.recipientName)}${this.recipientEmailValue()}</div>
      <div><strong>From:</strong> ${safeValue(this.sendingName)} - ${safeValue(this.sendingEmail)}</div>
    `)
  }

  deliveryDateValue() {
    if (this.sendingMethod !== 'email') return ''
    const [today, selectedDate, _dateValues] = validationDates(this.deliveryDate.input)
    const deliveryMonth = selectedDate.toLocaleString(LOCALE, { month: 'long' })
    const deliveryDay = selectedDate.getDate()

    let deliveryDate = `on <strong>${deliveryMonth} ${deliveryDay}, ${selectedDate.getFullYear()}</strong>`
    if (today.valueOf() === selectedDate.valueOf()) deliveryDate = `<strong>today, ${deliveryMonth} ${deliveryDay}</strong>`

    return `<div>Your gift email will be sent ${deliveryDate}</div>`
  }

  recipientEmailValue() {
    if (this.sendingMethod !== 'email') return ''
    return ` - ${safeValue(this.recipientEmail)}`
  }

  toggleEmailFields(event) {
    this.sendingMethod = event.target.value
    this.container.find('.js-gifts-flow__sending-method-email').toggleClass('d-none')
  }

}
