const INGREDIENT_CONTAINER = '.js-recipe-preference-ingredient'
const DESTROY_INPUT = '.js-recipe-preference-ingredient__destroy'
const INGREDIENT_TAG = '.js-recipe-preference-ingredient__tag'

class EditRecipePreferences {
  constructor(container) {
    this.container = container
    this.ingredients = $(INGREDIENT_CONTAINER)
    this.ingredientsSearch = $('.js-input-list-autocomplete')
    this.bind()
  }

  bind() {
    this.ingredientsSearch.on(
      'list_autocomplete:selected',
      (_event, element) => { this.toggleIngredient(element.innerText, true) }
    )

    this.ingredients.on('click', '.close', (event) => {
      this.toggleIngredient(event.delegateTarget.innerText, false)
    })

    this.checkDisabled()
  }

  toggleIngredient(tag, checked) {
    const ingredient = this.ingredients.filter(`[data-recipe-tag-name="${tag.trim()}"]`)
    ingredient.find(INGREDIENT_TAG).toggleClass('is-hidden', !checked).attr('aria-hidden', !checked)
    ingredient.find(DESTROY_INPUT).val(checked ? '0' : '1')

    this.checkDisabled()
  }

  checkDisabled() {
    const selected = this.ingredients.find(`${DESTROY_INPUT}[value="0"]`)
    this.ingredientsSearch.find('input').prop('disabled', selected.length >= 2)
  }
}

$(function() {
  const container = $('.js-recipe-preferences')
  if (container.length) {
    new EditRecipePreferences(container)
  }
})
