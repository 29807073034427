class Contacts {
  constructor(searchInput) {
    this.searchInput = searchInput

    this.bind()
  }

  bind() {
    $(document).on('keypress', this.submitToHelpCenter.bind(this));
  }

  submitToHelpCenter(event) {
    if(this.isEnterPressed(event) && this.searchInput.is(":focus")) {
      event.preventDefault()
      const submitUrl = this.isZendeskSearch() ?
        'https://purplecarrot.zendesk.com/hc/en-us/search?query=' :
        'https://faq.purplecarrot.com/en/?q='
      window.location.href = submitUrl + this.searchInput.val()
    }
  }

  isZendeskSearch() {
    return this.searchInput.hasClass('js-submit-to-zendesk')
  }

  isEnterPressed(event) {
    return event.which == 13
  }
}

$(function() {
  $('.js-submit-to-zendesk').each(function() {
    new Contacts($(this))
  })
})
