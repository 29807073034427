import AlternativePaymentButton from './alternative_payment_button'

let session = null
const BUTTON = '.js-apple-pay-button'
const PROVIDER = 'BraintreeApplePayCard'
const PAYMENT_OPTIONS = {
  supportedNetworks: [
    "visa",
    "masterCard",
    "discover"
  ]
}

export default class ApplePayButton extends AlternativePaymentButton {
  constructor(container, addressForm, price = 0) {
    super(container, addressForm, BUTTON)
    this.label = this.button.data('label')
    this.payment_type = this.button.data('payment-type') || 'final'
    this.loaded = false
    this.price = price
    this.applePayInstance = null
    this.spinner = this.container.find('.js-apple-pay-button__spinner')
  }

  renderButton() {
    BraintreeClient.applePayLoaded().then((instance) => {
      this.applePayInstance = instance
      this._render()
      this.bind()
    })
  }

  bind() {
    this.button.on('click', this._createApplePaySession.bind(this))
  }

  set enabled(value) {
    this._enabled = value
    this.container.find('[name^="user[payment_method_attributes]"]').attr('disabled', !value)

    return this._enabled;
  }

  get enabled() {
    return this.enabled;
  }

  _render() {
    const supportsApplePay = window.ApplePaySession && (
      (ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments())
    )

    if (supportsApplePay) {
      this.loaded = true
      this.button.html(`<a class="apple-pay-button apple-pay-button-black js-apple-pay-express-button"></a>`)
    }
  }

  _createApplePaySession() {
    PAYMENT_OPTIONS['total'] = {
      "label": this.label,
      "type": this.payment_type,
      "amount": this.price
    }

    if (this.button.data('required-shipping-info')) {
      PAYMENT_OPTIONS['requiredShippingContactFields'] = ["name", "postalAddress", "phone"]
    }

    var paymentRequest = this.applePayInstance.createPaymentRequest(PAYMENT_OPTIONS);

    session = new ApplePaySession(3, paymentRequest)
    session.begin();

    session.onvalidatemerchant = this.validateMerchant.bind(this, session)
    session.onpaymentauthorized = this.authorizePayment.bind(this, session)
  }

  validateMerchant (session, event) {
    this.applePayInstance.performValidation({
      validationURL: event.validationURL,
      displayName: 'Purple Carrot'
    }).then(function (merchantSession) {
      session.completeMerchantValidation(merchantSession);
    }).catch(function (validationErr) {
      console.error('Error validating merchant:', validationErr);
      session.abort();
    });
  }

  authorizePayment(session, event) {
    this.applePayInstance.tokenize({
      token: event.payment.token
    }).then((payload) => {
      this.toggleProcessing(true)
      this.fillInNonceAndSubmit(payload.nonce, PROVIDER, this._normalizeAddress(event))
      session.completePayment(ApplePaySession.STATUS_SUCCESS);
    }).catch(function (tokenizeErr) {
      console.error('Error tokenizing Apple Pay:', tokenizeErr);
      session.completePayment(ApplePaySession.STATUS_FAILURE);
    });
  }

  _normalizeAddress(event) {
    const address = event.payment.shippingContact;
    if (!address) return {}

    return {
      line1: address.addressLines[0],
      line2: address.addressLines[1],
      firstName: address.givenName,
      lastName: address.familyName,
      city: address.locality,
      postalCode: address.postalCode,
      state: address.administrativeArea,
      phone: (address.phoneNumber.match(/(\d+)/g) || []).join('')
    }
  }
}
