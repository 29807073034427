import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step'
import PaymentForm, {PAYMENT_INFO_TITLE} from 'javascripts/consumer/components/payment_form'
export default class PaymentStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)
    this.paymentPresent = container.data('paymentPresent')
    this.bind()
  }

  bind() {
    this.container.on('fragment:updated', this.handleFragmentUpdate.bind(this))
  }

  onActivated(stateWas) {
    if (this.paymentPresent && stateWas !== 'completed') {
      this.completeStep()
      return
    }

    this.initPaymentForm()
  }

  afterActivated() {
    $(PAYMENT_INFO_TITLE).focus();
  }

  onCompleted() {
    this.paymentPresent = true
  }

  handleFragmentUpdate(event, replacingElement, $element, data) {
    if (
      data.xhr.status === 200 &&
      $element.data('fragment') == 'payment-header-completed' &&
      $element.is('.is-completed')
    ) {
      this.completeStep()
    }

    this.initPaymentForm()
  }

  initPaymentForm() {
    const paymentForm = this.container.find('[data-fragment="signups-steps-payment-form"]')
    if (paymentForm.data('payment-form')) return

    this.container.find('[data-toggle="expandable-popover"]').expandablePopover()
    new PaymentForm(this.container, $('.js-payment-address-form'))
    paymentForm.data('payment-form', true)
  }
}
