import CloseIcon from 'icons/icon-ui--close.svg';

export default $ => {
  $(() => $(document.body).on('click', 'a[data-iframe-modal]', e => {
    e.preventDefault();
    let $modal = $(`
      <div class="modal fade modal--iframe" tabindex="-1">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close modal">
                <img src="${CloseIcon}" width="24" height="24" alt="" />
              </button>
              <iframe src="${e.target.getAttribute('href')}" />
            </div>
          </div>
        </div>
      </div>
    `);
    $(document.body).append($modal);
    $modal.modal('show');
  }));
}
