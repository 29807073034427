const CLIENT_LOADED = $.Deferred();

export default class BraintreeClient {
  static loaded(clientInstance) {
    CLIENT_LOADED.resolve(clientInstance)
  }

  constructor(form) {
    this.form = form
  }

  createToken(params, callback) {
    CLIENT_LOADED.promise().then((clientInstance) => {
      clientInstance.request({
        endpoint: 'payment_methods/credit_cards',
        method: 'post',
        data: params
      }, callback)
    })
  }

  static paypalLoaded() {
    return CLIENT_LOADED.promise().then((clientInstance) => {
      return braintree.paypalCheckout.create({ client: clientInstance })
    })
  }

  static applePayLoaded() {
    return CLIENT_LOADED.promise().then((clientInstance) => {
      return braintree.applePay.create({ client: clientInstance });
    })
  }

  static googlePayLoaded() {
    return CLIENT_LOADED.promise().then((clientInstance) => {
      return braintree.googlePayment.create({
        client: clientInstance, // From braintree.client.create, see below for full example
        googlePayVersion: 2,
        googleMerchantId: BRAINTREE_GOOGLE_PAY_MERCHANT_ID
      })
    })
  }

  static venmoLoaded() {
    return CLIENT_LOADED.promise().then((clientInstance) => {
      return Promise.all([
        braintree.dataCollector.create({client: clientInstance, paypal: true}),
        braintree.venmo.create({client: clientInstance})
      ]);
    })
  }
}
