import currentUserData from 'javascripts/consumer/models/current_user_data'

const PLANTRY_SECTION = '.c-weekly-menu-recipes #plantry'

class HeapVisibilityTracker {
  constructor(element) {
    this.element = element
    this.options = { threshold: this.element.data('threshold') || 0.75 }
    this.observer = new IntersectionObserver(this._handleVisibility.bind(this), this.options);

    this.observer.observe(element[0])
  }

  _handleVisibility(entries) {
    if (!entries[0].isIntersecting) return;

    heap.track('plantry_list_viewed', {
      user_status: currentUserData ? 'logged_in' : 'logged_out',
      page_viewed: '/weekly-menu/meal-kits'
    });
    this.observer.unobserve(this.element[0]);
  }
}

$(function() {
  if (typeof(heap) == "undefined") return;

  $(PLANTRY_SECTION).each(function() {
    new HeapVisibilityTracker($(this))
  })
})
