import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step'
import { scrollToUnlessVisible } from 'javascripts/consumer/utils/scroll_to'

export default class AddressStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)
    this.validAddress = this.container.find('form').data('validAddress')
    this.bind()
  }

  bind() {
    this.container.on('ajax:success', 'form', this.completeAddress.bind(this))
    this.container.on('fragment:updated', 'form', this.onFragmentUpdated.bind(this));
  }

  onActivated(stateWas) {
    this.container.find('[data-toggle="expandable-popover"]').expandablePopover()
    if (stateWas === 'completed') return
    this.validAddress = this.container.find('form').data('validAddress')
    if (this.validAddress) this.completeStep()
  }

  canComplete() {
    return this.validAddress || false
  }

  completeAddress() {
    this.validAddress = true
    this.completeStep()
  }

  onFragmentUpdated(_event, _replacingElement, _element, data) {
    if (data.xhr.status == 200) return
    this.container.find('[data-toggle="expandable-popover"]').expandablePopover()
    this.scrollToError()
  }

  scrollToError() {
    const errorElement = this.container.find('.is-invalid:first')
    if (errorElement.length > 0) scrollToUnlessVisible(errorElement)
  }
}
