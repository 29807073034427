import AccountFlow from 'javascripts/consumer/components/accordion_flow'
import AccountStep from './account_step'
import PlanStep from './plan_step'
import AddressStep from './address_step'
import PaymentStep from './payment_step'
import PaymentCompletedStep from './payment_completed_step'
import { showOnPageLoad as showModalsOnPageLoad } from 'javascripts/consumer/components/bootstrap_modal'

const SIGNUPS_CONTROLLER_SELECTOR = '.js-signups__accordion-flow';

class Step {
  constructor(container) {
    this.container = container
    this.sidebar = $('.js-signups__side-unit')
    this.bind()
    this._renderSidebar()
  }

  bind() {
    this.accordion = new AccountFlow(this.container, {
      account: AccountStep,
      plan: PlanStep,
      address: AddressStep,
      payment: PaymentStep,
      payment_completed: PaymentCompletedStep
    });
    $('.modal').on('ajax:success', this._handleModalSubmission.bind(this))
    this.sidebar.on('fragment:updated', this._renderSidebar.bind(this))
  }

  _handleModalSubmission(event) {
    const modalHidden = $.Deferred()
    const fragmentsUpdated = $.Deferred()

    $(document).one('fragments:updated', () => fragmentsUpdated.resolve())
    $(event.currentTarget).on('hidden.bs.modal', () => modalHidden.resolve()).modal('hide')

    $.when(fragmentsUpdated, modalHidden).done(() => showModalsOnPageLoad())
  }

  _renderSidebar() {
    this.sidebar.find('[data-toggle="expandable-popover"]').expandablePopover()
    $('.payment-method__subscription_agreement_plan_price').text(
      this.sidebar.find('.c-card__plan-price').text()
    )
  }
}

$(function() {
  const signupsContainer = $(SIGNUPS_CONTROLLER_SELECTOR)
  if (signupsContainer.length === 0) return
  new Step(signupsContainer)
})
