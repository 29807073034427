const RECIPE_DETAIL = '.js-recipe-details'
const ADD_FAVORITE_BUTTON = '.js-recipe-favorite-add'
const DEL_FAVORITE_BUTTON = '.js-recipe-favorite-delete'
const FAVORITES_PATH = '/api/v1/favorites'

class RecipeDetail {
  constructor(container) {
    this.container = container
    this._detail_popover();
  }

  _detail_popover() {
    $(this.container).find('[data-toggle="expandable-popover"]').expandablePopover()
  }
}

$(function() {
  new RecipeDetail(RECIPE_DETAIL)
})

$(document).on('click', ADD_FAVORITE_BUTTON, function() {
  const el = this;

  $.ajax({
    url: FAVORITES_PATH,
    type: 'POST',
    data: { product_id: el.getAttribute('data-product') },
    success: function(_event, _status, resp) {
      if (resp.status === 201) {
        $(ADD_FAVORITE_BUTTON).addClass('d-none');
        $(DEL_FAVORITE_BUTTON).find('span').html('Added');
        $(DEL_FAVORITE_BUTTON).addClass('transition').removeClass('d-none');

        setTimeout(() => {
          $(DEL_FAVORITE_BUTTON).find('span').text('In Favorites');
        }, 1500);
      }
    }
  });
});

$(document).on('click', DEL_FAVORITE_BUTTON, function() {
  const el = this;

  $.ajax({
    url: FAVORITES_PATH + '/' + el.getAttribute('data-product'),
    type: 'DELETE',
    success: function(_event, _status, resp) {
      if (resp.status === 204) {
        $(DEL_FAVORITE_BUTTON).addClass('d-none');
        $(ADD_FAVORITE_BUTTON).find('span').html('Removed');
        $(ADD_FAVORITE_BUTTON).addClass('transition').removeClass('d-none');

        setTimeout(() => {
          $(ADD_FAVORITE_BUTTON).find('span').text('Add to Favorites');
        }, 1500);
      }
    }
  });
});

$(document).on('mouseout', `${ADD_FAVORITE_BUTTON}, ${DEL_FAVORITE_BUTTON}`, function() {
  $(this).removeClass('transition');
});
