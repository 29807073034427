class RecipesNav {
  constructor(container) {
    this.container = container
    this.searchWrapper = $('.js-recipes-nav__search-wrapper')
    this.searchOpen = $('.js-recipes-nav__search-open')
    this.searchClose = $('.js-recipes-nav__search-close')
    this.categoriesContainer = $('.js-recipes-nav__categories-container')
    this.categoriesToggle = $('.js-recipes-nav__categories-toggle')

    this.bind()
  }

  bind() {
    this.searchOpen.on('click', () => {
      this.searchWrapper.toggleClass('is-active')
      this.searchWrapper.find('input').focus()
      this.categoriesContainer.toggleClass('d-none')
      this.categoriesToggle.toggleClass('d-none')
    })

    this.searchClose.on('click', this.hideSearch.bind(this))

    this.categoriesToggle.on('click', () => {
      this.categoriesContainer.toggleClass('is-visible');
      return false
    })
    
    this.container.on('click', '.js-recipes-nav__subcategory-trigger', this.showSubCategory)
  }

  hideSearch(event) {
    this.searchWrapper.find('input').val('')
    this.searchWrapper.removeClass('is-active')
    this.categoriesContainer.removeClass('d-none')
    this.categoriesToggle.removeClass('d-none')
    this.searchOpen.focus()
    return false
  }

  hideSearchIfBlank(event) {
    if (!event.currentTarget.value || event.currentTarget.value.trim() === '') this.hideSearch()
  }

  showSubCategory(event) {
    event.preventDefault();

    const currentTargetCategories = $(event.currentTarget).siblings('.js-recipes-nav__subcategories')
    const enabled = currentTargetCategories.hasClass('is-visible')

    $('.js-recipes-nav__subcategories').removeClass('is-visible')
    currentTargetCategories.toggleClass('is-visible', !enabled)
  }
}

$(function() {
  const container = $('.js-recipes-nav__container')
  if (container.length > 0) new RecipesNav(container)
})
