import isIosDevice from 'javascripts/consumer/utils/is_ios_device.js'
import isChromeBrowser from 'javascripts/consumer/utils/is_chrome_browser.js'
import AlternativePaymentButton from './alternative_payment_button'

const BUTTON = '.js-venmo-button'
const PROVIDER = 'BraintreeVenmo'

export default class VenmoButton extends AlternativePaymentButton {
  constructor(container, addressForm) {
    super(container, addressForm, BUTTON)
    this.loaded = false
    this.venmoInstance = null
    this.deviceData = null
    this.spinner = this.container.find('.js-venmo-button__spinner')
  }

  renderButton() {
    BraintreeClient.venmoLoaded().then((results) => {
      this.deviceData = results[0]
      this.venmoInstance = results[1]

      if (!this._isBrowserSupported()) {
        console.log('Browser does not support Venmo');
        return;
      }

      this.loaded = true
      this.bind()
    })
  }

  bind() {
    this.button.on('click', this._handleVenmoPayment.bind(this))
  }

  _isBrowserSupported() {
    return this.venmoInstance.isBrowserSupported() &&
      !(isIosDevice() && isChromeBrowser()) &&
      !window.ReactNativeWebView
  }

  _handleVenmoPayment() {
    this.toggleProcessing(true)

    this.venmoInstance.tokenize((tokenizeErr, payload) => {
      if (tokenizeErr) {
        this.toggleProcessing(false)
        this._handleVenmoError(tokenizeErr);
      } else {
        this.fillInNonceAndSubmit(payload.nonce, PROVIDER, {})
      }
    });
  }

  _handleVenmoError(err) {
    if (err.code != 'VENMO_CANCELED' || err.code != 'VENMO_APP_CANCELED')
      Rollbar.error(err.message)
  }
}
