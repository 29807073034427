import CreditCardFrom from 'javascripts/consumer/components/credit_card_form'
import PaypalButton from 'javascripts/consumer/components/paypal_button'
import ApplePayButton from 'javascripts/consumer/components/apple_pay_button'
import GooglePayButton from 'javascripts/consumer/components/google_pay_button'
import VenmoButton from 'javascripts/consumer/components/venmo_button'

const HIDE_ALL_FORMS = '.js-payment-method-hide-all'
const PAYMENT_METHOD_CC_SUBMIT = '#payment-method-cc-submit'
const FORM_TOGGLEABLE = '.js-payment-method-toggleable'
const PRICE = '.js-total-price'
const PRICE_OVERRIDE = '.js-total-price-override'
const CREDIT_CARD = '#payment-method-cc'
const PAYPAL = '#payment-method-paypal'
const APPLE_PAY = '#payment-method-apple-pay'
const GOOGLE_PAY = '#payment-method-google-pay'
const VENMO = '#payment-method-venmo'
const WEEKLY_SUBSCRIPTION_AGREEMENT = '#weekly-subscription-agreement'

export const PAYMENT_INFO_TITLE = '.js-payment-info-title'

export default class PaymentForm {
  constructor(container, primaryAddressForm) {
    this.container = container
    this.primaryAddressForm = primaryAddressForm

    this.paymentMethods = {}
    this.paymentMethods[CREDIT_CARD] = new CreditCardFrom(this.container.find('.js-payment-credit-card')),
    this.paymentMethods[PAYPAL] = new PaypalButton(this.container.find('#payment-method-paypal'), this.primaryAddressForm),
    this.paymentMethods[APPLE_PAY] = new ApplePayButton(this.container.find('#payment-method-apple-pay'), this.primaryAddressForm, price()),
    this.paymentMethods[GOOGLE_PAY] = new GooglePayButton(this.container.find('#payment-method-google-pay')),
    this.paymentMethods[VENMO] = new VenmoButton(this.container.find('#payment-method-venmo')),

    this._bind()
  }

  on() {
    return this.container.on.apply(this.container, arguments)
  }

  get paymentButtons() {
    return [
      this.paymentMethods[PAYPAL],
      this.paymentMethods[APPLE_PAY],
      this.paymentMethods[GOOGLE_PAY],
      this.paymentMethods[VENMO]
    ]
  }

  _bind() {
    this.container.on('click', FORM_TOGGLEABLE, this._toggleForm.bind(this))
    this.container.on('click', HIDE_ALL_FORMS, this._hideAllForms.bind(this))
    this.container.on('change', WEEKLY_SUBSCRIPTION_AGREEMENT, this._toggleSubmitButton.bind(this))
    this._toggleSubmitButton()

    for (const payment in this.paymentMethods) {
      const paymentMethod = this.paymentMethods[payment]
      if (!paymentMethod.onLoad || paymentMethod.loaded) continue;

      paymentMethod.onLoad.then(this._showToggleable.bind(this, payment))
    }
  }

  _showToggleable(payment) {
    this.container.find(`${FORM_TOGGLEABLE}[data-target="${payment}"]`).removeClass('d-none')
  }

  _toggleForm(event) {
    const activePaymentMethod = $(event.currentTarget).data('target')
    this._togglePaymentSections(activePaymentMethod)
    $(HIDE_ALL_FORMS).removeClass('d-none')
  }

  _togglePaymentSections(activePaymentMethod) {
    this.container.find(FORM_TOGGLEABLE).each((index, element) => {
      const paymentButton = $(element)
      this._togglePaymentSection(paymentButton, paymentButton.data('target') == activePaymentMethod)
    })

    for (let payment in this.paymentMethods) {
      this.paymentMethods[payment].enabled = (payment == activePaymentMethod)
    }

    this.container.trigger('toggle:cc_form', activePaymentMethod == CREDIT_CARD)
    this.container.find(PAYMENT_METHOD_CC_SUBMIT).toggleClass('d-none', activePaymentMethod != CREDIT_CARD)
  }

  _togglePaymentSection(paymentButton, active) {
    const collapsible = $(paymentButton.data('target'))
    paymentButton.toggleClass('is-active', active).toggleClass('d-none', !active).attr('aria-expanded', active)
    collapsible.collapse(active ? 'show' : 'hide')
  }

  _hideAllForms() {
    this._togglePaymentSections()
    const toggleable = this.container.find(FORM_TOGGLEABLE);
    $(HIDE_ALL_FORMS).addClass('d-none')

    toggleable.each((_index, element) => {
      if (this.paymentMethods[$(element).data('target')].loaded) {
        $(element).removeClass('d-none')
      }
    })
    toggleable[0].focus();
  }

  /**
   * Disables submit button if WEEKLY_SUBSCRIPTION_AGREEMENT is not checked and vice-versa
   *
   * @private
   */
  _toggleSubmitButton() {
    const agreementChecked = this.container.find(WEEKLY_SUBSCRIPTION_AGREEMENT).prop('checked')
    const paymentMethodCCSubmitButton = this.container.find(`${PAYMENT_METHOD_CC_SUBMIT} button`)
    paymentMethodCCSubmitButton.toggleClass('c-button--spinner', agreementChecked).attr('disabled', !agreementChecked)
    paymentMethodCCSubmitButton.find('.c-button__spinner').toggleClass('d-none', !agreementChecked)
  }
}

export function price() {
  let price_tag = $($(PRICE_OVERRIDE)[0] || $(PRICE)[0])

  return parseFloat(price_tag.text().trim().replace('$', '')) || 0
}
