enum BreakpointMedia {
  XL = '(min-width: 1200px)',
  LG = '(min-width: 1025px)',
  MD = '(min-width: 769px)',
  SM = '(min-width: 600px)',
  XS = '(min-width: 415px)',
  XXS = '(max-width: 414px)',
}

enum FeatureFlag {
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
  VENMO = 'venmo',
  THANKSGIVING = 'thanksgiving',
  RECIPE_PREFERENCES = 'recipe_preferences',
  CELEBRATE_THANKSGIVING = 'celebrate_thanksgiving',
  AUTO_SKIP = 'one_off_box',
  FRIENDBUY = 'friendbuy',
  BILLING_ADDRESS = 'billing_address',
  BRAINTREE_AVS = 'braintree_avs',
  SKIP_WELCOME_SURVEY = 'skip_welcome_survey',
  REGIONAL_DISCLAIMER = 'regional_availability_disclaimer',
  APPLE_EXPRESS_CHECKOUT = 'apple_express_checkout',
  FROZEN_MEALS = 'frozen_meals',
  MOBILE_APP_PROMO = 'mobile_app_promo',
  FEDEX_VALIDATION = 'fedex_validation',
  CPPP_DISPLAYED = 'cppp_displayed',
  MK_RTE_GROCERY_VERBIAGE = 'mk_rte_grocery_verbiage',
  BUNDLES = 'bundles',
  EXTENDED_AREA_SURCHARGE = 'extended_area_surcharge',
  RTE_PRODUCT_SERVINGS = 'rte_product_servings',
  PROGRAMS = 'programs',
  PROGRAMS_PLAN_SELECT = 'programs_plan_select',
  HIGHER_CONVERSION_ONBOARDING = 'higher_conversion_onboarding',
}

enum Route {
  UPCOMING_MENUS = '/upcoming-menus',
  CARTS = '/menus/:menuId/carts/:cartId/edit',
  CARTS_LEGACY = '/carts/:cartId/edit',
  ORDER_HISTORY = '/order-history',
  CELEBRATIONS = '/celebrations_order',
  GIVEAWAYS = '/giveaways',
  PROFILE = '/profile',
  PROFILE_EDIT_PAYMENT = '/profile?edit=payment',
  PROFILE_EDIT_PLAN_PREFERENCES = '/profile?edit=plan_preference',
  PROFILE_EDIT_PLAN = '/profile?edit=plan',
  PROFILE_ADD_ADDRESS = '/profile?add=address',
  BRAINTREE_CLIENT_CODE = '/braintree_client_code',
  BRAINTREE_CC_CALLBACK = '/payment_methods/credit_cards',
  REACTIVATE = '/subscription/reactivate',
  LOGOUT = '/users/sign_out',
  MY_FAVORITES = '/myfavorites',
  REDEEM_CODE = '/redeem-a-gift',
  TRACKING = '/tracking',
  EDIT_RECIPE_PREFERENCES = '/recipe-preferences/edit',
  WEEKLY_MENU_MEALS = '/weekly-menu/meal-kits',
  WEEKLY_MENU_PREPARED_MEALS = '/weekly-menu/prepared-meals',
  WEEKLY_MENU_READY_TO_EAT = '/weekly-menu/ready-to-eat',
  LESS_PREP = '/less-prep',
  BUNDLES = '/weekly-menu?filter=bundles#bundles',
  SIGNUP_PLAN = '/users/sign_up',
  SIGNUP_PROGRAM = '/programs/sign_up',
  SIGNUP_CREATE_ACCOUNT = '/sign_up/create_account',
  SIGNUP_CHECKOUT = '/sign_up',
  SIGNUP_CHECKOUT_ADDRESS = '/sign_up/address',
  SIGNUP_CHECKOUT_REDEEM = '/redeem_signup',
  FROZEN_MEALS = '/frozen-meals',
  PLANTRY_MEALS = '/weekly-menu/meal-kits#plantry',
  GROCERY_MEALS = '/weekly-menu/grocery',
  REFER = '/refer',
  PROFILE_MOBILE = '/profile/mobile',
  WELCOME_SURVEY = '/subscription/welcome',
  MANAGE_SUBSCRIPTION = '/manage-subscription',
  GIFTS = '/gifts',
  PLANS = '/plans',
  WHY_PLANTS = '/why-plants',
}

enum PageTitle {
  DEFAULT = 'Purple Carrot',
  UPCOMING_MENUS = 'Vegan Recipes | Purple Carrot',
  CARTS = 'Change Meals | Purple Carrot',
  ORDER_HISTORY = 'Order History | Purple Carrot',
  CELEBRATIONS = 'Celebrations | Purple Carrot',
  GIVEAWAYS = 'Share A Box | Purple Carrot',
  PROFILE = 'My Account | Purple Carrot',
  MY_FAVORITES = 'Favorites | Purple Carrot',
  TRACKING = 'Track Package | Purple Carrot',
  REFER = 'Refer & Earn | Purple Carrot',
}

enum ApiRoute {
  UPCOMING_MENUS = '/api/v1/upcoming_menus',
  GROUPED_MENU_ITEMS = '/api/v1/grouped_menu_items',
  CARTS = '/api/v1/carts',
  CELEBRATIONS = '/api/v1/celebrations',
  CELEBRATION_MENUS = '/api/v1/celebration_menus',
  SHIPPING_ADDRESSES = '/api/v1/shipping_addresses',
  SKIP_MENU_SURVEY = '/api/v1/skip_menu_surveys',
  WELCOME_SURVEY = '/api/v1/welcome_surveys',
  USERS = '/api/v1/users',
  CURRENT_USER = '/api/v1/users/me',
  PAYMENT_METHOD = '/api/v1/users/me/payment_method',
  NOTIFICATIONS = '/api/v1/notifications',
  ORDER_HISTORY = '/api/v1/orders',
  SUBSCRIPTION = '/api/v1/subscription',
  ACTIVATE = '/api/v1/subscription/activate',
  REACTIVATE = '/api/v1/subscription/reactivate',
  DEACTIVATE = '/api/v1/subscription/deactivate',
  FAVORITES = '/api/v1/favorites',
  REDEEM_CODE = '/api/v1/code_redemption',
  GIVEAWAYS = '/api/v1/giveaways',
  CURRENT_SESSION = '/api/v1/sessions/current',
  PLANS = '/api/v1/plans',
  PLANS_SHORT = '/api/v1/plans?shortformat=true',
  FB_SIGNATURE = '/api/v1/users/me/friendbuy',
  MENU_PRODUCTS = '/api/v1/menu-products',
  WEEKLY_NEWS = '/api/v1/weekly_news',
  PRODUCTS = '/api/v1/products',
}

enum ErrorMessage {
  UNKNOWN = 'An unknown error has occurred',
  FORGOT_EMAIL = 'Don’t forget your friend’s email!',
  CHANGES_NOT_SAVED = 'Your changes haven’t been saved. Please try again.',
  SOLD_OUT_ON_UNSKIP = 'Unable to unskip this order because the plan is sold out.',
  SOLD_OUT_SERVING = 'We’re sorry, this serving size is sold out.',
  MAX_BOX = 'Box maximum reached! Remove an item from your cart to add this item.',
  MIN_BOX = 'Unable to make this change for March 3 cart, this will bring your cart '
    + 'below the minimum of $50',
  CELEBRATION_SOLDOUT = "We're sorry, the quantity you have selected exceeds availability. "
    + 'Please reduce your quantity or choose a different week!',
  INVALID_EMAIL = 'Sorry, this email address is invalid.',
  ALREADY_SUBSCRIBED = 'Great news! You should already be receiving our emails.',
  CHAR_LIMIT_ADDRESS2 = '* Character limit reached. Please ensure that only Apartment/Suite'
    + ' information is entered, and that any special delivery instructions are only entered'
    + ' in the field below',
  UNVERIFIED_ADDRESS = 'The shipping address you entered could not be'
    + ' verified. Please confirm the address & correct any errors.',
}

enum HeapEvent {
  ANCHOR_MEALKITS = 'js-heap-anchor-mealkits',
  ANCHOR_FRESHPREPARED = 'js-heap-anchor-freshprepared',
  ANCHOR_LUNCH = 'js-heap-anchor-lunch',
  ANCHOR_BREAKFAST = 'js-heap-anchor-breakfast',
  ANCHOR_PLANTRY = 'js-heap-anchor-plantry',
  ANCHOR_LESS_PREP = 'js-heap-anchor-less-prep',
  ANCHOR_PROGRAMS = 'js-heap-anchor-programs',
  ANCHOR_BUNDLE = 'js-heap-anchor-bundle',
  ANCHOR_RTE_DINNER = 'js-heap-anchor-rte-dinner',
  ANCHOR_RTE_LUNCH = 'js-heap-anchor-rte-lunch',
  ANCHOR_RTE_BREAKFAST = 'js-heap-anchor-rte-breakfast',
  ADD_TO_CART_DINNER = 'js-heap-add-to-cart-dinner',
  ADD_TO_CART_LUNCH = 'js-heap-add-to-cart-lunch',
  ADD_TO_CART_BREAKFAST = 'js-heap-add-to-cart-breakfast',
  ADD_TO_CART_PLANTRY = 'js-heap-add-to-cart-plantry',
  ADD_TO_CART_PREPARED = 'js-heap-add-to-cart-prepared',
  ADD_TO_CART_BUNDLE = 'js-heap-add-to-cart-bundle',
  TRASH_CAN_DINNER = 'js-heap-trash-can-dinner',
  TRASH_CAN_LUNCH = 'js-heap-trash-can-lunch',
  TRASH_CAN_BREAKFAST = 'js-heap-trash-can-breakfast',
  TRASH_CAN_PLANTRY = 'js-heap-trash-can-plantry',
  TRASH_CAN_BUNDLE = 'js-heap-trash-can-bundle',
  TRASH_CAN_PREPARED = 'js-heap-trash-can-prepared',
  TWO_SERVING_TOGGLE = 'js-heap-2-serving-toggle',
  FOUR_SERVING_TOGGLE = 'js-heap-4-serving-toggle',
  QUANTITY_TICKER_PLANTRY = 'js-heap-quantity-ticker-plantry',
  QUANTITY_TICKER_BUNDLE = 'js-heap-quantity-ticker-bundle',
  QUANTITY_TICKER_PREPARED = 'js-heap-quantity-ticker-prepared',
  QUANTITY_DROPDOWN_CART_SUMMARY_BUNDLES = 'js-heap-quantity-dropdown-cart-summary-bundles',
  QUANTITY_DROPDOWN_CART_SUMMARY_PLANTRY = 'js-heap-quantity-dropdown-cart-summary-plantry',
  QUANTITY_DROPDOWN_CART_SUMMARY_PREPARED = 'js-heap-quantity-dropdown-cart-summary-prepared',
  QUANTITY_DROPDOWN_CART_SUMMARY_PLANTRY_AMOUNT = 'js-heap-quantity-dropdown-cart-summary-plantry-amount',
  QUANTITY_DROPDOWN_CART_SUMMARY_BUNDLES_AMOUNT = 'js-heap-quantity-dropdown-cart-summary-bundles-amount',
  QUANTITY_DROPDOWN_CART_SUMMARY_PREPARED_AMOUNT = 'js-heap-quantity-dropdown-cart-summary-prepared-amount',
  TWO_SERVING_TOGGLE_CART_SUMMARY = 'js-heap-2-serving-toggle-cart-summary',
  FOUR_SERVING_TOGGLE_CART_SUMMARY = 'js-heap-4-serving-toggle-cart-summary',
  REMOVE_CART_SUMMARY = 'js-heap-remove-cart-summary',
  CART_SAVE = 'js-heap-cart-save',
  CART_SUMMARY_ARROW = 'js-heap-cart-summary-arrow',
  UNSAVED_CONTINUE_EDITING = 'js-heap-unsaved-continue-editing',
  UNSAVED_CONTINUE_SAVING = 'js-heap-unsaved-continue-saving',
  UNSAVED_DISCARD_CHANGES = 'js-heap-unsaved-discard-changes',
  ANCHOR_HOME_PAGE = 'js-heap-navigate-home-page',
  CELEBRATION_CONFIRM_DECREMENT_BOX = 'js-heap-celebration-decrement-box',
  CELEBRATION_CONFIRM_INCREMENT_BOX = 'js-heap-celebration-increment-box',
  CELEBRATION_CLICKED_CONFIRM = 'js-heap-celebration-clicked-confirm',
  CELEBRATION_CLICKED_MONTH_TAB = 'js-heap-celebration-clicked-month-tab',
  CELEBRATION_CLICKED_VIEW_UPCOMING_DELIVERIES = 'js-heap-celebration-clicked-view-upcoming-deliveries',
  CELEBRATION_CLICKED_EDIT_ADDRESS = 'js-heap-celebration-clicked-edit-address',
  CELEBRATION_CLICKED_EDIT_PAYMENT = 'js-heap-celebration-clicked-edit-payment',
  CELEBRATION_CLICKED_CANCEL = 'js-heap-celebration-clicked-cancel',
  CELEBRATION_CLICKED_CONFIRM_CANCEL = 'js-heap-celebration-clicked-confirm-cancel',
  CELEBRATION_CLICKED_UPSELL_MODAL = 'js-heap-celebration-clicked-upsell-modal',
  EDIT_CART_UPSELL_CLOSED = 'js-heap-edit-cart-upsell-closed',
  EDIT_CART_UPSELL_VIEW_ALL = 'js-heap-edit-cart-upsell-view-all',
  EDIT_CART_UPSELL_SAVE = 'js-heap-edit-cart-upsell-save',
}

enum HeapCustomEvent {
  CART_MIN_ORDER_VALUE_ERROR = 'CartMinOrderValueError',
  CART_FREE_SHIPPING_MESSAGE = 'CartFreeShippingMessage',
  QUANTITY_TICKER_SUMMARY_PLANTRY_AMOUNT = 'QuantityTickerSummaryPlantryAmount',
  QUANTITY_TICKER_SUMMARY_BUNDLE_AMOUNT = 'QuantityTickerSummaryBundleAmount',
  QUANTITY_TICKER_SUMMARY_PREPARED_AMOUNT = 'QuantityTickerSummaryPreparedAmount',
  FAVORITE_PAGE_VISITED = 'favorite_page_visited',
  FAVORITE_PRODUCT_ADDED = 'favorited_product',
  FAVORITE_PRODUCT_REMOVED = 'unfavorited_product',
  FAVORITE_RECIPE_PAGE_VISITED = 'favorite_recipe_visited',
  AUTO_SKIP_MODAL_ORDER_ON_DEMAND = 'auto_skip_modal_order_on_demand',
  AUTO_SKIP_MODAL_WEEKLY_ORDER = 'auto_skip_modal_weekly_order',
  EDIT_CART_UPSELL = 'edit-cart-upsell',
  EDIT_CART_UPSELL_PRODUCT_ADDED = 'edit-cart-upsell-product-added',
  EDIT_CART_UPSELL_VIEWED = 'edit-cart-upsell-viewed',
  UPDATE_BILLING_ADDRESS = 'update_billing_address',
  PLANTRY_LIST_VIEWED = 'plantry_list_viewed',
  SIGNUP_SUCCESS_CREDIT_CARD = 'SignUpFunnel-Success-Payment-CreditCard',
  PROFILE_SUCCESS_CREDIT_CARD = 'Profile-Success-Payment-CreditCard',
  SIGNUP_SUCCESS_EXPRESS_PAYPAL = 'SignUpFunnel-Success-Express-Paypal',
  SIGNUP_SUCCESS_PAYMENT_PAYPAL = 'SignUpFunnel-Success-Payment-Paypal',
  PROFILE_SUCCESS_PAYPAL = 'Profile-Success-Payment-Paypal',
  SIGNUP_SUCCESS_EXPRESS_GOOGLE_PAY = 'SignUpFunnel-Success-Express-Google',
  SIGNUP_SUCCESS_PAYMENT_GOOGLE_PAY = 'SignUpFunnel-Success-Payment-Google',
  PROFILE_SUCCESS_GOOGLE_PAY = 'Profile-Success-Payment-Google',
  SIGNUP_SUCCESS_EXPRESS_APPLE_PAY = 'SignUpFunnel-Success-Express-Apple',
  SIGNUP_SUCCESS_PAYMENT_APPLE_PAY = 'SignUpFunnel-Success-Payment-Apple',
  PROFILE_SUCCESS_APPLE_PAY = 'Profile-Success-Payment-Apple',
  SIGNUP_SUCCESS_PAYMENT_VENMO = 'SignUpFunnel-Success-Payment-Venmo',
  PROFILE_SUCCESS_VENMO = 'Profile-Success-Payment-Venmo',
}

enum PaymentService {
  CREDIT_CARD = 'BraintreeCreditCard',
  PAYPAL = 'Braintree',
  GOOGLE_PAY = 'BraintreeGooglePayCard',
  APPLE_PAY = 'BraintreeApplePayCard',
  VENMO = 'BraintreeVenmo',
}

enum PaymentUpdateFunnel {
  STANDARD_SIGNUP = 'Standard SignUp',
  EXPRESS_SIGNUP = 'Express SignUp',
  USER_PROFILE = 'User Profile',
}

export {
  BreakpointMedia,
  FeatureFlag,
  Route,
  PageTitle,
  ApiRoute,
  ErrorMessage,
  HeapEvent,
  HeapCustomEvent,
  PaymentService,
  PaymentUpdateFunnel,
};
