class ManageSubscriptionForm {
  constructor(form) {
    this.form = form
    this.requiredInput = this.form.find('.js-subscription-deactivation-reason')
    this.formSubmitButton = this.form.find('[type="submit"]')
    this.isAnyOptionSelected = this.form.find('[type="radio"]:checked').length > 0

    this.bind()
    this.toggleSubmitButton()
  }

  bind() {
    this.form.on('change', '[type="radio"]', () => { this.isAnyOptionSelected = true; this.toggleSubmitButton() })
    this.requiredInput.on('keyup', this.toggleSubmitButton.bind(this))
  }

  toggleSubmitButton() {
    const disabled = !this.isAnyOptionSelected ||
      (this.requiredInput.length > 0 && this.requiredInput.val().trim().length == 0)

    this.formSubmitButton.prop('disabled', disabled).toggleClass('disabled', disabled);
  }
}

class CancelForm extends ManageSubscriptionForm {
  bind() {
    super.bind()
    this.form.on('click', '.js-cancel-subscription-button', (event) => { this.triggerCancellationReasonSelectedEvent(event) })
  }

  triggerCancellationReasonSelectedEvent(event) {
    event.preventDefault()
    this.form.trigger('cancellation-reason-selected', this.selectedReason)
  }

  submit() {
    this.form.trigger('submit');
  }

  get selectedReason() {
    return this.form.find('[type="radio"]:checked').val()
  }
}

class ManageSubscription {
  constructor(container) {
    this.container = container
    this.pauseForm = new ManageSubscriptionForm(container.find('.js-pause-subscription-form'))
    this.cancelForm = new CancelForm(container.find('.js-cancel-subscription-form'))
    this.subscriptionStatus = container.data('subscription-status')
    this.modal = container.find('#cancel-rebuttal-modal')

    this.bind()

    if (container.data('show-pause-opened')) {
      this.openPausePanel()
    }
  }

  bind() {
    this.container.on('cancellation-reason-selected', '.js-cancel-subscription-form', (_, reason) => this.showCancelRebuttalModalOrSubmit(reason))
    this.container.on('show.bs.collapse', '.js-subscription-management__togglable', this.addActiveClass.bind(this))
    this.modal.on('click', '.js-continue-with-cancellation', (event) => { this.continueWithCancellation(event) })
  }

  addActiveClass(event) {
    this.container.find('.js-subscription-management__togglable').removeClass('is-active')
    $(event.target).closest('.js-subscription-management__togglable').addClass('is-active')
  }

  showCancelRebuttalModalOrSubmit(reason) {
    const rebuttalModal = this.modal.find(`.js-cancel-rebuttal-modal-content-${reason}`)
    if ( rebuttalModal[0] === undefined || (reason == 'my_own' && (this.subscriptionStatus == 'paused' || this.subscriptionStatus == 'returning')) ) {
      this.cancelForm.submit();
    } else {
      this.modal.find('*[class^="js-cancel-rebuttal-modal-content-"]').addClass('d-none')
      rebuttalModal.removeClass('d-none')
      this.modal.modal('show')
    }
  }

  continueWithCancellation(event) {
    event.preventDefault()
    this.cancelForm.submit();
  }

  openPausePanel() {
    this.container.find('#subscription-management-pause #pause').trigger('click');
  }
}

$(() => {
  const subscriptionManagement = $('.js-subscription-management')
  if (subscriptionManagement.length == 0) return;

  new ManageSubscription(subscriptionManagement)
})
