import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step'
import TogglePanel from 'javascripts/consumer/components/toggle_panel'

const MIN_GIFT_VALUE = 50
const MAX_GIFT_VALUE = 1000

export default class PlanStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)
    new TogglePanel(this.container)
    this.salesTaxEnabled = $('.js-gift-sales-tax').length !== 0
    this.bind()
    this.togglePicker($('.js-gifts-flow__details-input-value').val())
    this.changeSideUnit($('.js-gifts-flow__details-input-value'))
  }

  bind() {
    this.container.on('keydown', '.js-gifts-flow__details-input-value', (event) => {
      if(event.key === '.') {
        event.preventDefault()
        return false
      }
    });
    this.container.on('input', '.js-gifts-flow__details-input-value', (event) => {
      const giftValue = event.target.value;
      $(event.target).val(giftValue.replace(/[^0-9]*/g,''))
      this.toggleInvalid(!this.isGiftValueValid(giftValue))
    });
    this.container.on('change', '.js-gifts-flow__details-input-value', (event) => {
      this.togglePicker(event.target.value)
      this.changeSideUnit(event.target)
      this.toggleInvalid(false)
    })
    this.container.on('keypress', '.js-gifts-flow__details-picker-value', (event) => {
      if(event.key === 'Enter') {
        this.changeSideUnit(event.target)
        $('.js-gifts-flow__details-input-value').trigger('change')
      }
    });
    this.container.on('change', '.js-gifts-flow__details-picker-value', (event) => {
      this.changeSideUnit(event.target)
      $('.js-gifts-flow__details-input-value').trigger('change')
    })
  }

  isGiftValueValid(giftValue) {
    return giftValue >= MIN_GIFT_VALUE && giftValue <= MAX_GIFT_VALUE;
  }

  getClosestValidGiftValue(giftValue) {
    const sanitizedValue = giftValue || 0;

    if (sanitizedValue < MIN_GIFT_VALUE) return MIN_GIFT_VALUE
    if (sanitizedValue > MAX_GIFT_VALUE) return MAX_GIFT_VALUE
    return giftValue;
  }

  toggleInvalid(isInvalid) {
    $('.js-gifts-flow__details-input-group').toggleClass('is-invalid', isInvalid);
    $('.js-accordion-step__complete').toggleClass('is-disabled', isInvalid);
  }

  togglePicker(currentValue) {
    const validValue = this.getClosestValidGiftValue(currentValue)
    $(`.js-gifts-flow__details-picker-value[value!=${validValue}]`).prop('checked', false)
    $(`.js-gifts-flow__details-picker-value[value=${validValue}]`).prop('checked', true)
  }

  changeSideUnit(target) {
    const selectedGift = $(target)
    const giftAmount = this.getClosestValidGiftValue(parseInt(selectedGift.val()))
    $('.js-gift-price').html(giftAmount)
    $('.js-gifts-flow__details-input-value').val(giftAmount)
    $('.js-gift-description').html(selectedGift.data('giftDescription'))
  }
  
}
