// STYLEGUIDE JS TPC COMPONENTS
import './tpc/components/_select'
import './tpc/components/_radio_group'
import './tpc/components/_input_toggle'
import './tpc/components/_password_toggle'
import './tpc/components/_input_range'
import './tpc/components/_input_ticker'
import './tpc/components/_input_list_autocomplete'
import './tpc/components/_tab.js'

// THIRD PARTY JS
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
