import type { ICartItem, ReducedCartItem } from 'models/CartItem';
import { HeapCustomEvent, PaymentService, PaymentUpdateFunnel } from 'config';

const heapPaymentUpdateEvents = {
  [PaymentService.CREDIT_CARD]: {
    [PaymentUpdateFunnel.STANDARD_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_CREDIT_CARD,
    [PaymentUpdateFunnel.EXPRESS_SIGNUP]: null,
    [PaymentUpdateFunnel.USER_PROFILE]: HeapCustomEvent.PROFILE_SUCCESS_CREDIT_CARD,
  },
  [PaymentService.PAYPAL]: {
    [PaymentUpdateFunnel.STANDARD_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_PAYMENT_PAYPAL,
    [PaymentUpdateFunnel.EXPRESS_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_EXPRESS_PAYPAL,
    [PaymentUpdateFunnel.USER_PROFILE]: HeapCustomEvent.PROFILE_SUCCESS_PAYPAL,
  },
  [PaymentService.GOOGLE_PAY]: {
    [PaymentUpdateFunnel.STANDARD_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_PAYMENT_GOOGLE_PAY,
    [PaymentUpdateFunnel.EXPRESS_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_EXPRESS_GOOGLE_PAY,
    [PaymentUpdateFunnel.USER_PROFILE]: HeapCustomEvent.PROFILE_SUCCESS_GOOGLE_PAY,
  },
  [PaymentService.APPLE_PAY]: {
    [PaymentUpdateFunnel.STANDARD_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_PAYMENT_APPLE_PAY,
    [PaymentUpdateFunnel.EXPRESS_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_EXPRESS_APPLE_PAY,
    [PaymentUpdateFunnel.USER_PROFILE]: HeapCustomEvent.PROFILE_SUCCESS_APPLE_PAY,
  },
  [PaymentService.VENMO]: {
    [PaymentUpdateFunnel.STANDARD_SIGNUP]: HeapCustomEvent.SIGNUP_SUCCESS_PAYMENT_VENMO,
    [PaymentUpdateFunnel.EXPRESS_SIGNUP]: null,
    [PaymentUpdateFunnel.USER_PROFILE]: HeapCustomEvent.PROFILE_SUCCESS_VENMO,
  },
};

class HeapAnalytics {
  static track(eventName: string, properties = {}): void {
    window.heap.track(eventName, properties);
  }

  static trackCartUpdated(
    initialSelection: ReducedCartItem[],
    cartItems: ICartItem[],
  ): void {
    const initialTotal = initialSelection
      .reduce((total, { quantity }) => total + quantity, 0);

    const selectedTotal = cartItems
      .reduce((total, { quantity }) => total + quantity, 0);

    if (initialTotal < selectedTotal) {
      window.heap.track('AddedExtra', {
        total_was: initialTotal,
        total: selectedTotal,
      });
    } else if (initialTotal > selectedTotal) {
      window.heap.track('RemovedExtra', {
        total_was: initialTotal,
        total: selectedTotal,
      });
    }
  }

  static trackSuccessfullyAuthPayment(
    funnel: PaymentUpdateFunnel,
    service: PaymentService,
  ): void {
    const heapEvent = heapPaymentUpdateEvents[service][funnel];
    if (!heapEvent) return;

    window.heap.track(heapEvent, {
      signup_payment_funnel: funnel,
      payment_service: service,
    });
  }
}

export default HeapAnalytics;
