import cardValidator from 'card-validator'
export default class PaymentFormValidation {
  constructor(form) {
    this.form = form
    this.promise = new $.Deferred
  }

  isValid() {
    let message

    if (this.isCardNumberBlank()) message = 'Please enter a valid credit card'
    if (!cardValidator.number(this.form.number.val()).isPotentiallyValid) message = message || 'The card number is not a valid credit card number.'
    if (this.isYearBlank()) message = message || "Your card's expiration year is invalid."
    if (this.isMonthBlank()) message = message || "Your card's expiration month is invalid."
    if (this.isExpired()) message = message || 'Your credit card is expired'

    if (message) {
      this.promise.reject(this.form, message)
    } else {
      this.promise.resolve(this.form)
    }

    return this.promise
  }

  isCardNumberBlank() {
    return this.form.number.val().length === 0
  }

  isMonthBlank() {
    return this.form.month.val().length === 0
  }

  isYearBlank() {
    return this.form.year.val().length === 0
  }

  isExpired() {
    const minExpirationDate = new Date(this.form.data('minExpiration'))
    const year = this.form.year.val()
    const month = this.form.month.val()

    if (year === '' || month === '') return false

    return minExpirationDate > new Date(year, parseInt(month) - 1)
  }
}
