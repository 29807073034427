import Select from 'javascripts/styleguide/tpc/components/_select'
const SELECT = '.js-select--extras'
const DINNER_SELECT = '.js-select--dinners'

export default class EditExtras {
  constructor(container) {
    this.container = container
    this.bind()
  }

  bind() {
    $(this.container)
      .on('show.bs.modal', this.onModalShown.bind(this))
      .on('change', SELECT, this.onExtrasChange.bind(this))
      .on('change', DINNER_SELECT, this.updatePrices.bind(this))
  }

  show() {
    this.container.modal('show')
  }

  get isRendered() {
    return this.container.length > 0;
  }

  get planPrice() {
    return parseFloat(this.summary.data('plan-price'))
  }

  get planMinimumDinners() {
    return parseInt(this.summary.data('plan-minimum-dinners'))
  }

  get extraPrice() {
    return parseFloat(this.summary.data('extra-price'))
  }

  get isPrepared() {
    return this.summary.data('prepared')
  }

  get summary() {
    return this.container.find('.js-edit-extras-summary')
  }

  onModalShown(event) {
    let extras = this.updatePrices()

    if (!this.isPrepared) {
      this.handleExtrasMax(extras)
    }

    this.container.find('[data-toggle="expandable-popover"]').expandablePopover()
  }

  onExtrasChange(event) {
    let extras = this.updatePrices()
    this.handleExtrasMax(extras)
  }

  selectedExtras() {
    let extras = {}

    if (this.isPrepared) {
      const dinners = parseInt(this.container.find(DINNER_SELECT).val())
      extras = { extraPrice: this.extraPrice * dinners, dinner: dinners, numberExtras: dinners }
    } else {
      extras = { extraPrice: 0, breakfast: 0, lunch: 0, dinner: 0, numberExtras: 0 }

      $(SELECT).each(function() {
        if($(this)[0].value) {
          extras[$(this)[0].value] += 1;

          let mealPrice = $($(this)[0].selectedOptions[0]).data('price');
          extras.extraPrice += parseFloat(mealPrice);

          extras.numberExtras += 1
        }
      })
    }

    return extras
  }

  handleExtrasMax(extras) {
    $(SELECT).each(function() {
      for (var type in extras) {
        if (extras[type] < gon.plan_max_extra_preferences[type]) {
          new Select($(this)).showValue(type)
        }
        else {
          new Select($(this)).hideValue(type)
        }
      }
    })
  }

  updatePrices() {
    let extras = this.selectedExtras()

    var totalPrice = this.planPrice + extras.extraPrice

    $('.js-price-without-tax').text(totalPrice.toFixed(2))
    // $('.js-tax-price').text(this.taxRate === undefined ? '--' : '$' + taxAmount.toFixed(2))
    $('.js-total-price').text(totalPrice.toFixed(2))
    $('.js-dinners-count').text(this.planMinimumDinners + extras.dinner)

    $('.js-extras-total-price').text(extras.extraPrice.toFixed(2))
    const pluralizedMeal = (extras.numberExtras == 1 ? 'Meal' : 'Meals')
    $('.js-number-of-extras').text(`${extras.numberExtras}x ${pluralizedMeal}`)

    return extras;
  }

  set selectedPlan(plan) {
    this.container.find('.js-meal-preferences-plan').val(plan)
  }
}
