import Cookies from 'js-cookie';
import { getUrlParameter } from 'javascripts/consumer/utils/navigation';

const FB_BANNER = '.js-fb-banner';
const FB_EXPIRY = 3650; // FB cookie expiration date 10 years

function renderFriendbuyBanner() {
  const advocateNamePlaceholder = Cookies.get('advocate_first_name') !== 'undefined' ? `${Cookies.get('advocate_first_name')}, ` : '';
  $(FB_BANNER).html(
    `<div class='c-alert'>
      <div class='c-alert__text'>
        <center class='d-md-block'>
          Your friend, ${advocateNamePlaceholder}referred you to eat with Purple Carrot! Get your discount at sign up.
        </center>
      </div>
    </div>`
  );
}

function updatePlanTiles(data) {
  $(data).find('[data-fragment]').each((i, elm) => {
    const newElm = $(elm);
    const dataFragment = $(newElm).attr('data-fragment');
    const elmToReplace = $(`[data-fragment="${dataFragment}"]`);
    elmToReplace.html(newElm.html());
  });
}

function requestFriendbuyCoupon() {
  const searchParams = new URLSearchParams(window.location.search);
  $.ajax({
    url: `/plans?${searchParams.toString()}`,
    success: updatePlanTiles,
  });
}

function friendbuyVisitorStatus() {
  friendbuyAPI.push([
    'getVisitorStatus',
    function (status) {
      const payload = status.payload;

      if (!(payload.isSelfReferral && payload.referralCodeBlocked)) {
        const referralCode = Cookies.get('referral_code');
        if (!referralCode || payload.referralCode !== referralCode) {
          Cookies.set('campaign_id', payload.campaignId, FB_EXPIRY);
          Cookies.set('referral_code', payload.referralCode, FB_EXPIRY);
          Cookies.set('advocate_id', payload.advocate && payload.advocate.customerId, FB_EXPIRY);
          Cookies.set('advocate_first_name', payload.advocate && payload.advocate.firstName, FB_EXPIRY);
          requestFriendbuyCoupon();
        }
        renderFriendbuyBanner();
      }
    },
  ]);
}

$(function() {
  if (getUrlParameter('utm_source') === 'friendbuy') {
    friendbuyVisitorStatus();
  }
})
