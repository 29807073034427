import { addParameterToURL, replaceURLParameter, removeURLParameter, getUrlParameter } from 'javascripts/consumer/utils/navigation'
import Cookies from 'js-cookie'

const PLAN_SELECT_SUBMIT = '.js-plan-select__submit'
const DINNER_TOGGLE = '.js-plan-select__dinners'
const PLAN_SELECTOR = '.js-plan-select__plan'
const PLAN_OPTIONS = '.js-plan-select__options'
const PLAN_TYPE = '.js-plan-select__plan-type'
const PREFERENCES_PRICE = '.js-plan-select__price'
const PLAN_SELECT_FORM = '.js-plan-select__form'
const PLANS_SUBMIT_CONTAINER = '.js-plan-select__submit-buttons'
const PLAN_ADD_EXTRA = '.js-plan-select__add-extra'

class PlanSelect {
  constructor(container) {
    this.container = container
    this._bind()
  }

  _bind() {
    this.container.on('click', PLAN_SELECTOR, this._selectPlan.bind(this))
    this.container.on('click', DINNER_TOGGLE, this._showDinnerPreferences.bind(this))
    this.container.on('click', PLAN_SELECT_SUBMIT, this._addDinnerPreference.bind(this))
    this.container.on('click', PLAN_TYPE, this._selectPlanType.bind(this))
    this.container.on('click', PLAN_ADD_EXTRA, this._checkExtra.bind(this))
  }

  _showDinnerPreferences(event) {
    const target = $(event.currentTarget)
    const value = target.val()
    const container = target.closest('[data-plan-group]')

    container.find(DINNER_TOGGLE).attr('aria-pressed', false).removeClass('is-selected')
    target.addClass('is-selected').attr('aria-pressed', true)
    container.find(PREFERENCES_PRICE).addClass('d-none').removeClass('is-selected')
    container.find(`${PREFERENCES_PRICE}[data-num-of-dinners="${value}"]`).addClass('is-selected').removeClass('d-none')

    this._updateExtrasPrice(target);
    this._updateTotalPrice(target);
  }

  _planContainer(element) {
    const container = element.closest(PLAN_TYPE)

    if (container.length > 0) {
      return container
    } else {
      return this.container
    }
  }

  _selectPlan(event) {
    const plan = $(event.currentTarget).data('plan-group')
    const planContainer = this._planContainer($(event.currentTarget))

    planContainer.find(`${PLAN_SELECTOR}, ${PLAN_OPTIONS}, ${PLAN_TYPE}`).removeClass('is-selected')
    planContainer.find(`${PLAN_SELECTOR}[data-plan-group="${plan}"], ${PLAN_OPTIONS}[data-plan-group="${plan}"]`).addClass('is-selected')
    $(event.currentTarget).closest(PLAN_TYPE).addClass('is-selected')
  }

  _selectPlanType(event) {
    const target = $(event.currentTarget)
    const targetPlanTypeSelector = `${PLAN_TYPE}[data-plan-type="${target.data('plan-type')}"]`;

    this.container.find(PLAN_TYPE).removeClass('is-selected')
    this.container.find(targetPlanTypeSelector).addClass('is-selected')

    if (target.data('plan-type') === 'programs') {
      $('#js-plan-select__pricing-step-title').text('2 - Meals Curated for You')
    } else {
      $('#js-plan-select__pricing-step-title').text('2 - How much food do you want in a week?')
    }
  }

  _trackPlanSelectedEvent() {
    const container = this.container.find('.is-selected')
    const servings = container.find('.js-plan-select__plan.is-selected').data('value')
    const meals = container.find('.js-plan-select__options.is-selected .js-plan-select__dinners.is-selected').data('value')
    const weeklyPrice = container.find(` ${PLAN_OPTIONS}.is-selected .js-plan-select__price.is-selected .js-weekly-price`).data('value')
    const firstWeekPrice = container.find(` ${PLAN_OPTIONS}.is-selected .js-plan-select__price.is-selected .js-first-week-price`).data('value')

    // GTM event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'plan_selected',
      servings: servings,
      meals: meals,
      weeklyPrice: weeklyPrice,
      firstWeekPrice: firstWeekPrice,
    });
  }

  _addDinnerPreference(event) {
    const submitButton = $(event.currentTarget)
    const planContainer = this._planContainer(submitButton)
    const toggle = planContainer.find(`${DINNER_TOGGLE}.is-selected:visible`)
    const checkedExtra = planContainer.find(`${PLAN_ADD_EXTRA}.is-checked:visible`);

    if (submitButton.attr('href')) {
      if (planContainer.data('planType') === 'programs') {
        submitButton.attr('href', 'programs/sign_up')
      } else {
        const buttonHref = removeURLParameter(submitButton.attr('href'), 'extra_meals[]')
        submitButton.attr('href', addParameterToURL(buttonHref, this._extraParams(toggle, checkedExtra)))
      }

      setTimeout(() => {
        this._trackPlanSelectedEvent()
      }, 0);
    } else {
      const form = submitButton.closest(PLAN_SELECT_FORM)
      const extraDinners = toggle.val()
      if (!extraDinners) return

      form.attr('action', replaceURLParameter(form.attr('action'), 'subscription[extra_dinners]', extraDinners))
    }
  }

  _extraParams(toggle, checkedExtra) {
    const dinners = []

    for (let i = parseInt(toggle.val()); i > 0; i--) {
      dinners.push('extra_meals[]=dinner')
    }

    if (dinners.length === 0) dinners.push('extra_meals[]=')

    // if we have any checked extras, add them to the array
    if (checkedExtra && checkedExtra.length > 0) {
      checkedExtra.each(function() {
        dinners.push(`extra_meals[]=${$(this).data('extra-type')}`)
      })
    }

    return dinners.join('&')
  }

  _totalSelectedExtrasPrice() {
    const checkedExtra = this.container.find(`${PLAN_ADD_EXTRA}.is-checked:visible`);
    let total = 0;
    checkedExtra.each(function() {
      total += parseFloat($(this).data('price'));
    });
    return total;
  }

  _updateTotalPrice(target) {
    const priceElm = target.closest(PLAN_OPTIONS).find('.js-first-week-price:visible');
    const noExtraPrice = priceElm.data('value');
    const newPrice = parseFloat(noExtraPrice) + this._totalSelectedExtrasPrice();
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(newPrice);

    priceElm.first().text(formattedPrice);
  }

  _updateExtrasPrice(target) {
    const extraPriceElm = target.closest(PLAN_OPTIONS).find('.js-extra-price:visible');
    const newPrice = this._totalSelectedExtrasPrice();
    const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(newPrice);
    extraPriceElm.text(formattedPrice);
  }

  _checkExtra(event) {
    const target = $(event.currentTarget);
    target.toggleClass('is-checked');

    this._updateExtrasPrice(target);
    this._updateTotalPrice(target);
  }
}

$(function() {
  const planSelect = $('.js-plan-select')
  if (planSelect.length === 0) return

  planSelect.each(function() { new PlanSelect($(this)) })

  $(document).on('fragments:updated', function() {
    $('.js-plan-select').each(function() { new PlanSelect($(this)) })
  })
})
