/*  The component accepts two arguments in constructor:
 *
 *    container - The jQuery Object with the accordion flow element
 *    steps     - The Map of steps with step names as keys and step objects as values.
 *                If there is no step object defined for a step the defaul AccordionFlowStep
 *                will be used.
 *
 *  const accordionFlowContainer = $('.js-gifts__accordion')
 *  new AccordionFlow(accordionFlowContainer, { choose_gift: ChoseGiftStep ])
 */
import { AccordionFlowStep, EVENTS as STEP_EVENTS }  from './accordion_flow/step'
import AccordionFlowHistory from './accordion_flow/history'

export default class AccordionFlow {
  constructor(container, steps = {}) {
    this.container = container
    this.steps = this.buildStepsFrom(steps)
    this.initialStep = this.steps.find((step) => { return step.isActive }) || this.steps[0]
    this.bind()

    this.history = new AccordionFlowHistory(this, this.initialStep)
    this.activeStep = this.initialStep.index
  }

  bind() {
    this.container.on(STEP_EVENTS.complete, (event, step) => { this.activeStep = step.index + 1 })
    this.container.on(STEP_EVENTS.activate, (event, step) => { this.activeStep = step && !isNaN(step.index) ? step.index : this.activeStepIndex })
  }

  render() {
    this.steps.forEach((step) => {
      if (step.index === this.activeStepIndex) return
      step.state = step.index > this.activeStepIndex ? 'inactive' : 'completed'
    })

    this.activeStep.state = 'active'
    this.history.navigate(this.activeStep)
  }

  isLastStep() {
    return this.activeStep.index === this.steps.length - 1
  }

  completeStep() {
    this.activeStep.completeStep()
  }

  activateStep(name) {
    for (let i = 0; i < this.steps.length; ++i) {
      if (this.steps[i].name === name) {
        this.activeStep = i
        break;
      }
    }
  }

  set activeStep(stepIndex) {
    stepIndex === undefined ? this.initialStep.index : stepIndex
    stepIndex = Math.min(this.steps.length - 1, stepIndex)

    if (stepIndex !== this.activeStepIndex) {
      this.activeStepIndex = stepIndex
      this.render()
    }
  }

  get activeStep() {
    return this.steps[this.activeStepIndex]
  }

  buildStepsFrom(steps) {
    return this.container.find('[data-accordion-step]').map(function(index, element) {
      const step = $(element)
      return new (steps[step.data('accordionStep')] || AccordionFlowStep)(step, index)
    }).toArray()
  }
}
