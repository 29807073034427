import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step'
import Rails from '@rails/ujs'

export default class PlanStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)
    this.bind()
  }

  bind() {
    this.container.on('change', '.js-signup-plan-select', this.updatePlan.bind(this))
  }

  onCompleted() {
    const selectedPlan = this.container.find('.js-signup-plan-select input:checked')
    this.trackPlanSelected()
    this.container.find('.js-accordion-step__completed-body').html(selectedPlan.data('value'))
  }

  updatePlan(event) {
    const plan = event.target.value;
    let $form = $(event.target).closest('form');
    if ($form.data('remote')) {
      Rails.fire($form[0], 'submit');
    } else {
      $form.trigger('submit');
    }
    $('.js-signups__plan-select').removeClass('is-chefs_choice-selected is-quick_easy-selected is-high_protein-selected is-four_servings-selected').addClass(`is-${plan}-selected`)
  }

  trackPlanSelected() {
    const goals = this.container.data('goals')
    window._vis_opt_goal_conversion = window._vis_opt_goal_conversion || []

    goals.forEach(function(goal) {
      window._vis_opt_queue.push(function() { _vis_opt_goal_conversion(goal) })
    })
  }
}
