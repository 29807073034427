import Popover from 'bootstrap/js/src/popover';

const NAME = "expandablePopover";
const DATA_KEY = "expandablePopover";

class ExpandablePopover extends Popover {
  static get NAME() {
    return NAME;
  }

  static get DATA_KEY() {
    return DATA_KEY;
  }

  constructor(element, config) {
    super(element, config);
    this._bind();
  }

  _bind() {
    $(this.element).on('click', () => this.element.focus()); // fix for Safari which doesn't trigger focus on click
    $(this.element).on('show.bs.popover', this._setExpanded.bind(this));
    $(this.element).on('hide.bs.popover', this._setCollapsed.bind(this));
    $(window).on('keydown', this._closeOnEsc.bind(this));

    if (this._isTipFocusable) {
      $(window).on('click', this._hideOnClickOutSide.bind(this));
    } else {
      $(this.element).on(
        "focusout.bs.popover",
        this._handleFocousOut.bind(this)
      );
    }
  }

  _setCollapsed() {
    if (!this._isShown) return;
    this.element.removeAttribute("aria-controls");
    $(this.element)
      .attr("aria-expanded", "false")
      .attr("aria-label", $(this.element).data("label"));
  }

  _setExpanded() {
    const tip = $(this.getTipElement());

    this.element.setAttribute("aria-controls", tip.attr("id"));
    $(this.element).attr("aria-expanded", "true");
    this.element.removeAttribute("aria-label");

    if (this._isTipFocusable) {
      tip.attr('tabindex', '-1').trigger('focus');
    }
  }

  _hideOnClickOutSide(event) {
    if (!this._isShown) return;

    const target = $(event.target);
    const tip = $(this.getTipElement());
    const iconClick = target.is(this.element) || $(this.element).has(target).length;
    const tipClick = tip.has(target).length || target.is(tip);

    if (!tipClick && !iconClick) {
      this._hide();
    }
  }

  _closeOnEsc(event) {
    if (event.key === 'Escape' && this._isShown) {
      this._hide();
      $(this.element).trigger('focus');
    }
  }

  _handleFocousOut(event) {
    this._hide();
  }

  _hide() {
    if (this._isShown) {
      this.hide();
    }
  }

  get _isShown() {
    return $(this.element).attr("aria-expanded") === "true";
  }

  get _isTipFocusable() {
    return $(this.element).data("container");
  }
}


$.fn[NAME] = function (config) {
  return this.each(function () {
    let data = $(this).data(DATA_KEY);
    const _config = typeof config === "object" ? config : null;

    if (!data && /dispose|hide/.test(config)) {
      return;
    }

    if (!data) {
      data = new ExpandablePopover(this, _config);
      $(this).data(DATA_KEY, data);
    }

    if (typeof config === "string") {
      if (typeof data[config] === "undefined") {
        throw new TypeError(`No method named "${config}"`);
      }
      data[config]();
    }
  });
};

export default ExpandablePopover;
