import 'rangeslider.js'

export const INPUT_RANGE_SELECTOR = '.js-input-range'
const INPUT = '.js-input-range__input'
const SELECTED_VALUE = '.js-input-range__selected-value'
const TICKS = '.js-input-range__tick'
const SLIDER_OPTIONS = {
  polyfill: false,
  rangeClass: 'c-input-range rangeslider',
  disabledClass: 'c-input-range--disabled rangeslider--disabled',
  horizontalClass: 'c-input-range--horizontal rangeslider--horizontal',
  verticalClass: 'rangeslider--vertical',
  fillClass: 'c-input-range--fill rangeslider__fill',
  handleClass: 'c-input-range__handle'
}

export default class InputRange {
  constructor(rangeInput) {
    this.rangeInput = rangeInput
    this.input = rangeInput.find(INPUT)
    this.ticks = rangeInput.find(TICKS)
    this.selectedValue = rangeInput.find(SELECTED_VALUE)

    if (this.input.data('plugin_rangeslider')) return

    this.input.rangeslider($.extend(SLIDER_OPTIONS, { onSlide: this._setSelected.bind(this, true) }))
    this._rangeHandle.html(this.input.data('check'))
    this._setSelected(this.input.is('.is-activated'))
    this._bind()
  }

  get isActivated() {
    return this.rangeInput.is('.is-activated')
  }

  on() {
    return this.rangeInput.on.apply(this.rangeInput, arguments)
  }

  _bind() {
    $(window).on('resize', () => { setTimeout(this._setSelected.bind(this, this.isActivated), 300) })
    this._rangeSlider.on('click', this._setSelected.bind(this, true))
  }

  _setSelected(activated = false) {
    this.rangeInput.toggleClass('is-activated', activated)
    this.selectedValue.css({left: this._position }).text(this.input.val())
    this._updateSelectedTicks()
    this.input.trigger('input_range:selected', this)
  }

  _updateSelectedTicks() {
    this.ticks.removeClass('is-selected')
    if (!this.isActivated) return

    this.ticks.filter(`[value="${this._value}"]`).addClass('is-selected')
  }

  get _value() {
    return this.input.val()
  }

  get _rangeSlider() {
    return this.input.data('plugin_rangeslider').$range
  }

  get _rangeHandle() {
    return this.input.data('plugin_rangeslider').$handle
  }

  get _position() {
    return this.input.data('plugin_rangeslider').position
  }
}

$(function() {
  $(INPUT_RANGE_SELECTOR).each(function() {
    new InputRange($(this))
  })
})
