import Rails from '@rails/ujs'
import ModalNotSaved from 'javascripts/consumer/components/profiles/modal_not_saved'
import PaymentForm from 'javascripts/consumer/components/payment_form'
import EditMealPreferences from 'javascripts/consumer/components/profiles/edit_meal_preferences'
import Modal from 'javascripts/consumer/components/bootstrap_modal'

const AUTO_SHOW_MODALS_SELECTOR = '.modal[data-modal-show="true"], .modal:has([data-modal-show="true"])'
const NOT_SAVED_CHANGES_SELECTOR = '.js-profiles__alert-not-saved'
const UPDATE_CC_BUTTON = '.js-profile-payment-update-cc'
const SPINNER = 'c-button--spinner'

class Profiles {
  constructor(container) {
    this.container = container

    this.initPaymentForm($('.js-profiles__forms-payment'))
    this.initAddressModal($('.js-profiles__forms-address'))
    this.initEditMealPreferences($('.js-profile-plan-select'))
    this.render()
    this.bind()
  }

  render() {
    if (window.location.href.includes('#plan-select')) {
      new Modal($('.js-plan-customization-link')).show()
    }
  }

  bind() {
    $(document)
      .on('ajax:success', '.modal', this.closeModals.bind(this))
      .on('show.bs.modal', '.js-profiles__forms-payment', (event) => { this.initPaymentForm($(event.target)) })
      .on('show.bs.modal', '.js-profiles__forms-address', (event) => { this.initAddressModal($(event.target)) })
      .on('show.bs.modal', this.trackModalChanges.bind(this))
      .on('click', '[data-profile-toggle]', (event) => { this.toggleProfileModal(event) })
      .on('click', '.js-sms-notification-form input', this.submitSMSNotificationChanges)
      .on('ajax:error', '.js-sms-notification-form', () => this.handleSMSNotificationError())
  }

  trackModalChanges(event) {
    const noChangesAlert = this.container.find(NOT_SAVED_CHANGES_SELECTOR).addClass('is-hidden');
    const modalToTrack = $(event.target)

    if (modalToTrack.data('modal-not-saved')) return;

    new ModalNotSaved(modalToTrack).onNotSaved(() => {
      noChangesAlert.removeClass('is-hidden')
    })
  }

  initAddressModal(addressModal) {
    addressModal.find('[data-toggle="expandable-popover"]').expandablePopover()
    addressModal.on('fragment:updated', 'form', function() {
      $(this).find('[data-toggle="expandable-popover"]').expandablePopover()
    })
  }

  initPaymentForm(paymentModal) {
    if (paymentModal.data('payment-modal-form')) return;

    const updateCCButton = this.container.find(UPDATE_CC_BUTTON)
    updateCCButton.attr('disabled', true).removeClass(SPINNER)

    paymentModal.on('fragments:beforeUpdate', function(event, promise) {
      event.stopPropagation()
      paymentModal.data('noChanges', true).modal('hide')
      paymentModal.on('hidden.bs.modal', function() { promise.resolve() })
    })

    const paymentForm = new PaymentForm(paymentModal, this.container.find('.js-payment-address-form'))
    paymentForm.on('toggle:cc_form', (event, toggleOnOff) => { updateCCButton.attr('disabled', !toggleOnOff).toggleClass(SPINNER, toggleOnOff) })

    paymentModal.find('[data-toggle="expandable-popover"]').expandablePopover()
    paymentModal.data('payment-modal-form', true)
  }

  closeModals(event) {
    const modalHidden = $.Deferred()
    const fragmentsUpdated = $.Deferred()

    $(document).one('fragments:updated', () => fragmentsUpdated.resolve())
    $(event.currentTarget).modal('hide').on('hidden.bs.modal', () => modalHidden.resolve($(event.currentTarget)))

    $.when(fragmentsUpdated, modalHidden).done((_, modal) => this.triggerModals(modal))
  }

  triggerModals(updatedModal) {
    $(AUTO_SHOW_MODALS_SELECTOR)
      .not(updatedModal)
      .removeAttr('data-modal-show').modal()
      .find('[data-modal-show]').removeAttr('data-modal-show')
  }

  initEditMealPreferences(mealPreferences) {
    if (mealPreferences.data('meal-preferences-modal-form')) return;

    new EditMealPreferences(mealPreferences)

    mealPreferences.data('meal-preferences-modal-form', true)
  }

  toggleProfileModal(event) {
    event.preventDefault()
    new Modal($(event.target)).show()
  }

  submitSMSNotificationChanges(e) {
    Rails.fire($(e.currentTarget).closest('form')[0], 'submit');
  }

  handleSMSNotificationError() {
    alert("Sorry, your changes have not been saved. Please contact us at help@purplecarrot.com if you have any questions.")
    location.reload()
  }
}

$(function() {
  $('.js-accounts-profiles').each(function(index, element) {
    new Profiles($(element))
  });
});
