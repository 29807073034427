export default class JsToggle {
  static toggle(element, toggle) {
    element.toggleClass('is-active', toggle);
    if (!$(element).data('target')) return
    $(element.data('target')).toggleClass('is-active', toggle);
  }

  constructor() {
    this.bind()
  }

  bind() {
    $(document).on('click', '.js-toggle', (event) => {
      event.preventDefault();
      JsToggle.toggle($(event.currentTarget))
    });
  }
}

$(function() {
  new JsToggle()
})
