import 'jquery-mask-plugin';
import HeapAnalytics from 'services/HeapAnalytics';

$(function() {
  var welcomeSurveyForm = $('.js-welcome-survey-form');
  var BIRTHDAY_REGEXP = /(\d{2})\/(\d{2})\/(\d{4})/;
  var HEADER_HEIGHT = 60;

  if(welcomeSurveyForm.length === 0) { return; }

  var welcomeSurveyBirthday = $('.js-welcome-survey-birthday');
  var welcomeSurveyEatingHabits = $('.js-welcome-survey-eating-habits');
  var welcomeSurveyMealsEachWeekWrapper = $('.js-welcome-survey-meals-each-week-wrapper');
  var welcomeSurveyBirthdayError = $('.js-welcome-survey-birthday-error');
  var welcomeSurveyText = $('.js-welcome-survey-text');
  var welcomeSurveyContainer = $('.js-welcome-survey-container');
  var welcomeSurveyForm = $('.js-welcome-survey-form');
  var thankYouContainer = $('.js-welcome-survey-thanks');
  var mobileAppBanner = $('.js-welcome-survey-mobile-app');
  mobileAppBanner.on('click', 'a', () => {
    HeapAnalytics.track('IOS_signup_banner_clicked');
  });

  welcomeSurveyBirthday.mask('00/00/0000');

  function toggleShowElement(element, show) {
    element.toggleClass('d-none', !show)
  }

  function questionsReplied() {
    const activeQuestionGroup = $(questionGroups[activeGroup]);
    let replies = activeQuestionGroup.find(':input:not(.js-input-range__input)').serializeArray()
    replies = replies.concat(activeQuestionGroup.find('.is-activated .js-input-range__input').serializeArray())

    return $.grep(replies, function(field) {
      return field.value != 'Other' && field.value.trim().length > 0
    }).length;
  }

  function scrollToQuestion(container) {
    container.scrollIntoView({ behavior: 'auto' });
  }

  function disableSubmitButton(disabled) {
    var activeQuestionGroup = $(questionGroups[activeGroup]);

    if(!disabled && (questionsReplied() < activeQuestionGroup.data('numberOfQuestions'))) {
      disabled = true
    }

    activeQuestionGroup.find('[type="submit"]').prop('disabled', disabled);
  }

  function birthdayInputError() {
    var birthday = BIRTHDAY_REGEXP.exec(welcomeSurveyBirthday.val());
    var error = 'Invalid format. Update to mm/dd/yyyy';

    if (welcomeSurveyBirthday.val().length === 0 || !birthday) {
      return error;
    }

    var birthdayDate = new Date(Date.parse(birthday.input, 'M/d/yyyy'));

    if (
      (birthdayDate.getMonth() + 1) == parseInt(birthday[1]) &&
      birthdayDate.getDate() == parseInt(birthday[2]) &&
      birthdayDate.getFullYear() == parseInt(birthday[3]) &&
      birthdayDate < new Date()
    ) {
      return null;
    } else {
      return error;
    }
  }

  function renderThankYou() {
    welcomeSurveyContainer.replaceWith(thankYouContainer.removeClass('d-none'));
    welcomeSurveyText.addClass('d-none');
    scrollToQuestion(thankYouContainer[0]);
    thankYouContainer.find('[autofocus]').focus()
    if (mobileAppBanner.length === 0) return;

    HeapAnalytics.track('IOS_signup_banner_viewed');
  }

  function updateRecipeSelection(event) {
    var questionWithOther = $(event.target).closest('.js-question-with-other');

    if (questionWithOther.length === 0) {
      disableSubmitButton(false);
      return;
    }

    var otherInput = questionWithOther.find('.js-survey-other-input');

    if (event.target.value == 'Other') {
      toggleShowElement(otherInput, true);
      disableSubmitButton(true);
    } else if (event.target.value == 'Yes' && gon.is_prepared_subscriber) {
      toggleShowElement(otherInput, true);
      disableSubmitButton(true);
    } else {
      toggleShowElement(otherInput, false);
      otherInput.val('');
      disableSubmitButton(false);
    }
  }

  var questionGroups = $('[data-question-group]')
  var activeGroup = 0
  var disabledInput = welcomeSurveyForm.find('input:disabled')[0]
  if(disabledInput != undefined) {
    activeGroup = $(disabledInput).closest('[data-step]').data('step') - 1
    toggleShowElement($(questionGroups[0]), false)
    toggleShowElement($(questionGroups[activeGroup]), true)
  } else {
    activeGroup = questionGroups.length
    renderThankYou()
  }

  welcomeSurveyBirthday.on('blur', function () {
    var error = birthdayInputError();

    if (error) {
      welcomeSurveyBirthday.closest('.welcome_survey_birthday').addClass('is-invalid');
      toggleShowElement(welcomeSurveyBirthdayError.text(error), true);
    } else {
      welcomeSurveyBirthday.closest('.welcome_survey_birthday').removeClass('is-invalid');
      toggleShowElement(welcomeSurveyBirthdayError.text(error), false);
    }
  });

  welcomeSurveyBirthday.on('keyup', function() {
    disableSubmitButton(false);
  })

  questionGroups.on('keyup touchend', '[type=text]', function(event) {
    if (event.target.value.trim().length > 0) {
      disableSubmitButton(false);
    } else {
      disableSubmitButton(true);
    }
  });

  questionGroups.on('change', '.js-welcome-survey-question-with-options', updateRecipeSelection);
  questionGroups.on('input_range:selected', '.js-welcome-survey-question-with-options', updateRecipeSelection);

  welcomeSurveyForm.on('ajax:send', () => {
    if (questionGroups.length > activeGroup) {
      toggleShowElement($(questionGroups[activeGroup]), false)
      toggleShowElement($(questionGroups[activeGroup + 1]), true)
      activeGroup += 1;

      scrollToQuestion(welcomeSurveyForm[0]);
      welcomeSurveyContainer.find('[autofocus]').focus()
    }

    if (questionGroups.length === activeGroup)  {
      renderThankYou();
    }
  });

  welcomeSurveyForm.on('submit', function() {
    var activeQuestionGroup = $(questionGroups[activeGroup])

    if(questionsReplied() < activeQuestionGroup.data('numberOfQuestions')) {
      return false
    }

    if (welcomeSurveyBirthday.is(':visible') && birthdayInputError() != null) {
      welcomeSurveyBirthday.focus()
      return false;
    }
    return true;
  })
});
