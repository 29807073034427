import googlePlaceApi from 'javascripts/consumer/components/google_places_api.js'

const COMPONENT_FORM = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  postal_code: 'short_name'
};

var autocomplete;

export default class AddressAutocomplete {
  constructor() {
    this.bind();
  }

  bind() {
    $(document).on('focus', '.js-address-autocomplete', this.suggestionsBasedOnLocation.bind(this))
    $(document).on('focus', '.js-address-autocomplete', this.initAutocomplete.bind(this))
  }

  initAutocomplete(event) {
    googlePlaceApi.onload.then(() => {
      $('.pac-container').remove();
      autocomplete = new google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */
        (event.target),
        {
          types: ['geocode'],
          componentRestrictions: {country: 'us'}
        }
      );

      autocomplete.addListener('place_changed', this.fillInAddress);
    });
  }

  fillInAddress() {
    var place = autocomplete.getPlace();
    $('.js-auto-complete__city').value = ''
    $('.js-auto-complete__state').value = ''
    $('.js-auto-complete__zip').value = ''

    // Avoid filling address if there is no result
    if(place == undefined || place.address_components == undefined)
      return;

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      if(addressType != 'street_number' && addressType != 'route') {
        if (COMPONENT_FORM[addressType]) {
          var val = place.address_components[i][COMPONENT_FORM[addressType]];
          if(addressType == 'administrative_area_level_1') {
            $('.js-auto-complete__state').val(val).trigger('change');
          }
          else if(addressType == 'locality') {
            $('.js-auto-complete__city').val(val);
          }
          else {
            $('.js-auto-complete__zip').val(val);
          }
        }
      }
    }
    $('.js-address-autocomplete').val(`${place.address_components[0][COMPONENT_FORM['street_number']]} ${ place.address_components[1][COMPONENT_FORM['route']]}`)
  }

  suggestionsBasedOnLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        var circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  }
}

$(function() {
  if(gon.address_autocomplete) {
    new AddressAutocomplete()
  }
})
