const HEADER_HEIGHT = 90

export function scrollToUnlessVisible(element) {
  const position = element.offset().top
  const viewHeigth = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const maxScroll = document.body.getBoundingClientRect().height - viewHeigth

  if (position < window.scrollY + HEADER_HEIGHT) window.scrollTo(0, Math.min(position - HEADER_HEIGHT, maxScroll))
}

export function scrollTo(element) {
  window.scrollTo(0, element.offset().top - HEADER_HEIGHT)
}
