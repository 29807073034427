import { navigateTo, replaceUrl } from 'javascripts/consumer/utils/navigation'

export default class AccordionFlowHistory {
  constructor(accordionFlow, initialStep) {
    this.accordionFlow = accordionFlow
    this.rootPath = accordionFlow.container.data('accordionFlowPath')
    replaceUrl.apply(this, this.buildPath(initialStep))

    this.bind()
  }

  bind() {
    window.onpopstate = this.navigateBack.bind(this)
  }

  navigate(step) {
    const path = [{ stepIndex: step.index }, 'AccordionFlowHistory', `${this.rootPath}/${step.path}${window.location.search}`]
    if (path[2] !== `${window.location.pathname}${window.location.search}`) navigateTo.apply(this, path)
  }

  buildPath(step) {
    return [
      { stepIndex: step.index },
      'AccordionFlowHistory',
      `${this.rootPath}/${step.path}${window.location.search}`
    ]
  }

  navigateBack(event) {
    const stepIndex = (event.state && event.state.stepIndex !== undefined) ? event.state.stepIndex : undefined
    if(!stepIndex) return
    if (this.accordionFlow.activeStepIndex > stepIndex) {
      this.accordionFlow.activeStep = stepIndex
    } else {
      this.accordionFlow.activeStep.beforeComplete().then(() => {
        this.accordionFlow.activeStep = stepIndex
      }, () => { this.replaceUrl.apply(this, this.buildPath(this.accordionFlow.activeStep)) })
    }
  }
}
