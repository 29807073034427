import YoutubeLoader from 'javascripts/consumer/utils/youtube_loader'

export var ytPlayers = [];

class VideoSection {
  constructor() {
    this.recipeActiveVideo = $('.js-recipes-videos-active .recipes-ytplayer');
    this.youtubePlayer = null
    this.recipesVideosList = $('.js-recipes-videos-list')
    this.bind();
  }

  loadVideo (event) {
    event.preventDefault()
    const item = $(event.currentTarget).closest('li a')

    $('.js-recipes-videos-list li a').removeClass('is-active')
    item.addClass('is-active')

    if (this.youtubePlayer) {
      this.youtubePlayer.loadVideoById(item.data('videoId'))
    }
  }

  bind() {
    this.recipesVideosList.on('click', 'a', this.loadVideo.bind(this));

    YoutubeLoader.onload.then(() => {
      this.youtubePlayer = new YT.Player(this.recipeActiveVideo[0], {
        videoId: this.recipeActiveVideo.data('youtube-id'),
        playerVars: { rel: '0' }
      });
    })
  }
}

$(function() {
  new VideoSection();
})
