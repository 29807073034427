import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step';

const PAYMENT_METHOD_CC_SUBMIT = '#payment-method-submit';
const WEEKLY_SUBSCRIPTION_AGREEMENT = '#payment-method-weekly-subscription-agreement';

export default class PaymentCompletedStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)

    this._bind(container)
    this.container = container
  }

  _bind() {
    this.container.parent().on('change', WEEKLY_SUBSCRIPTION_AGREEMENT, this._toggleSubmitButton.bind(this))
    this._toggleSubmitButton();
  }

  /**
   * Disables submit button if WEEKLY_SUBSCRIPTION_AGREEMENT is not checked and vice-versa
   *
   * @private
   */
  _toggleSubmitButton() {
    const agreementChecked = this.container.parent().find(WEEKLY_SUBSCRIPTION_AGREEMENT).prop('checked');
    const paymentMethodCCSubmitButton = this.container.parent().find(`${PAYMENT_METHOD_CC_SUBMIT} button`);
    paymentMethodCCSubmitButton.toggleClass('c-button--spinner', agreementChecked).attr('disabled', !agreementChecked);
    paymentMethodCCSubmitButton.find('.c-button__spinner').toggleClass('d-none', !agreementChecked);
  }
}
