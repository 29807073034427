$(function() {
  const stickyContainer = $('.js-sticky-container')
  if (stickyContainer.length === 0) return

  const stickyContainerParent = $('.js-sticky-anchor')[0]
  if (!stickyContainerParent) return

  function updateStickClass() {
    let position = stickyContainer[0].getBoundingClientRect()
    let parentPosition = stickyContainerParent.getBoundingClientRect()

    stickyContainer.toggleClass('is-anchored', position.y === (parentPosition.y + parentPosition.height))
  }

  $(document).on('scroll', updateStickClass)
  updateStickClass()
})
