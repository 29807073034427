import AlternativePaymentButton from './alternative_payment_button'

const GOOGLE_PAY_LOADED = new $.Deferred
const PROVIDER = 'BraintreeGooglePayCard'
const BUTTON = '.js-google-pay-button'

export function onGooglePayLoaded() {
  GOOGLE_PAY_LOADED.resolve()
}

export default class GooglePayButton extends AlternativePaymentButton {
  constructor(container, addressForm) {
    super(container, addressForm, BUTTON)

    this.loaded = false
    this.googlePayInstance = null
    this.paymentsClient = null
    this.spinner = this.container.find('.js-google-pay-button__spinner')
  }

  renderButton() {
    BraintreeClient.googlePayLoaded().then(this._render.bind(this))
  }

  _render(googlePaymentInstance) {
    this.googlePaymentInstance = googlePaymentInstance
    GOOGLE_PAY_LOADED.then(() => {
      this.paymentsClient = new google.payments.api.PaymentsClient({ environment: BRAINTREE_GOOGLE_PAY_ENV });

      return this.paymentsClient.isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: this.googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
        existingPaymentMethodRequired: true
      });
    }).then((isReadyToPay) => {
      if (isReadyToPay.result) {
        this.loaded = true
        this._bind()
      }
    });

  }

  _bind() {
    this.button.html(
      this.paymentsClient.createButton({ buttonColor: 'black', buttonType: 'short', onClick: this._pay.bind(this) })
    )
  }

  _pay(event) {
    event.preventDefault()

    this._createPaymentRequest().then((paymentData) => {
      this.toggleProcessing(true)

      this.googlePaymentInstance.parseResponse(paymentData, (err, result) => {
        if (err) {
          this.toggleProcessing(false)
          Rollbar.error(err)
          return
        }

        this._authorizedSuccessfully(result, paymentData)
      });
    })
  }

  _authorizedSuccessfully(result, paymentData) {
    this.fillInNonceAndSubmit(result.nonce, PROVIDER, this._normalizeAddress(paymentData))
  }

  _normalizeAddress(payload) {
    const address = payload.paymentMethodData.info.billingAddress
    const recipientName = address.name.split(' ')

    return {
      line1: address.address1,
      line2: address.address2,
      firstName: recipientName[0],
      lastName: recipientName[1],
      city: address.locality,
      postalCode: address.postalCode,
      state: address.administrativeArea,
      phone: (address.phoneNumber.match(/(\d+)/g) || []).join('').substr(1)
    }
  }

  _createPaymentRequest() {
    const paymentDataRequest = this.googlePaymentInstance.createPaymentDataRequest({
      transactionInfo: {
        currencyCode: 'USD',
        countryCode: 'US',
        totalPriceStatus: 'NOT_CURRENTLY_KNOWN'
      }
    })

    const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
    cardPaymentMethod.parameters.billingAddressRequired = true;
    cardPaymentMethod.parameters.allowedCardNetworks = ['VISA', 'MASTERCARD', 'DISCOVER']
    cardPaymentMethod.parameters.billingAddressParameters = { format: 'FULL', phoneNumberRequired: true };

    return this.paymentsClient.loadPaymentData(paymentDataRequest)
  }
}
