export default class RadioGroup {
  constructor(radioGroup) {
    this.radioGroup = radioGroup
    this.radioButtons = this.radioGroup.find('[role=radio]')
    this._bind()
    this._render()
  }

  _render() {
    $(this.radioButtons[0]).attr('tabindex', 0)
    for (let i = 1; i < this.radioButtons.length; i++) {
      $(this.radioButtons[i]).attr('tabindex', -1)
    }
  }

  _bind() {
    this.radioButtons.on('click', this._radioButtonSelected.bind(this))
  }

  _radioButtonSelected() {
    $(this.radioButtons).attr('aria-checked', false)
    $(this.radioButtons).attr('tabindex', -1)
    $(event.currentTarget).attr('aria-checked', true)
    $(event.currentTarget).attr('tabindex', 0)
  }

  _navigate(event) {
    if (NAVIGATION.indexOf(event.key) < 0) return
    event.preventDefault()

    if (!this.select.isActive) {
      this.select.toggleActive(true)
      this._selected.addClass(HOVERED).focus().attr('aria-selected', 'true')
      return
    }

    this._updateSelectedItem(event.key)
  }
}

$(function() {
  const activatePlugin = function() {
    $('[role=radiogroup]').each(function(index, element) { new RadioGroup($(element)) })
  }

  activatePlugin()
})
