const PREVIOUS_BTN = '.js-previous-button'
const NEXT_BTN = '.js-next-button'
const SURVEY_PROGRESS = '.js-survey-progress'
const QUESTION1 = '.js-survey__question1'
const QUESTION2 = '.js-survey__question2'
const THANK_YOU = '.js-survey__thank-you'
const FORM = '.edit_nps_survey'
const QUESTION_2_TEXT = '#nps_survey_question_2_answer'

import InputRange, {INPUT_RANGE_SELECTOR} from 'javascripts/styleguide/tpc/components/_input_range'

class NpsSurvey {
  constructor(container) {
    this.container = container
    this.surveyProgress = container.find(SURVEY_PROGRESS)
    this.form = container.find(FORM)

    this.question1Container = container.find(QUESTION1)
    this.question2Container = container.find(QUESTION2)
    this.thankYou = container.find(THANK_YOU)

    this.question1NextButton = this.question1Container.find(NEXT_BTN)
    this.question1PreviousButton = this.question1Container.find(PREVIOUS_BTN)
    this.question1InputRange = new InputRange(this.container.find(INPUT_RANGE_SELECTOR))
    this.question2NextButton = this.question2Container.find(NEXT_BTN)
    this.question2PreviousButton = this.question2Container.find(PREVIOUS_BTN)
    this.question2Text = container.find(QUESTION_2_TEXT)

    this.bind()
  }

  bind() {
    this.question1InputRange.on('change', this.onInputRangeActivated.bind(this))
    this.question1PreviousButton.prop('disabled', true)
    this.question1NextButton.prop('disabled', !this.question1InputRange.isActivated)
    this.question2PreviousButton.on('click', this.onQuestion2PreviousButtonPressed.bind(this))
    this.form.bind('ajax:success', this.onFormSubmit.bind(this))

    this.enableQuestion2Next()
  }

  // Triggers
  onInputRangeActivated() {
    this.question1NextButton.prop('disabled', false)
  }

  onQuestion2PreviousButtonPressed(event) {
    event.preventDefault()
    this.question1Container.removeClass('d-none')
    this.question2Container.addClass('d-none')
  }

  onFormSubmit(event) {
    let data = event.detail[0];
    if(this.isSurveyOnQuestion1() && this.isQuestionAnswered(data['question_1_answer']) ) {
      this.showQuestion2()
    } else if(this.isSurveyOnQuestion2()) {
      this.showThanksPage()
    }
  }

  // Actions
  showQuestion2() {
    this.question1Container.addClass('d-none')
    this.question2Container.removeClass('d-none')
  }

  showThanksPage() {
    this.question1Container.addClass('d-none')
    this.question2Container.addClass('d-none')
    this.thankYou.removeClass('d-none')
  }

  enableQuestion2Next() {
    this.question2NextButton.prop('disabled', false)
  }

  // Helpers
  isSurveyOnQuestion2() {
    return !this.question2Container.hasClass('d-none')
  }

  isSurveyOnQuestion1() {
    return !this.question1Container.hasClass('d-none')
  }

  isQuestionAnswered(question) {
    return question != null && question !== ""
  }
}

$(() => $('.js-survey-nps').each((_i, element) => new NpsSurvey($(element))));
