import InfiniteScroll from 'infinite-scroll'

class LoadMore {
  constructor() {
    this.loadMore = new InfiniteScroll('.js-recipes-display', {
                      path:  this.findPath(),
                      button: '.js-load-more',
                      loadOnScroll: false,
                      append: '.js-recipes-display .js-recipe',
                      history: false,
                      checkLastPage: '.js-load-more'
                    });
    this.bind();
  }

  findPath() {
    let searchPath = window.location.pathname

    if (window.location.search && window.location.search.length > 0) {
      searchPath += `${window.location.search}&page={{#}}`
    } else {
      searchPath += '?page={{#}}'
    }

    return searchPath
  }

  showLoading(path) {
    $('.js-load-animation').removeClass('d-none');
    $('.js-loading-text').text('LOADING');
  }

  hideLoading(path) {
    $('.js-load-animation').addClass('d-none');
    $('.js-loading-text').text('LOAD MORE');
  }

  bind() {
    this.loadMore.on('request', this.showLoading.bind(this))
    this.loadMore.on('load',this.hideLoading.bind(this))
  }
}

$(function() {
  $('.js-recipes-display').each((index, element) => new LoadMore($(element)))
})
