export default class AutoComplete {
  constructor() {
    this.bind()
  }

  bind() {
    $(document).on('input', '.js-auto-complete__zip', this.autoComplete.bind(this))
  }

  autoComplete() {
    const parent = $(document.activeElement).closest('form')
    const zip = $(document.activeElement).val()
    const stateInput = parent.find('.js-auto-complete__state');
    const cityInput = parent.find('.js-auto-complete__city');

    if(zip.match(/\d{5}/)) {
      $.getJSON('/zip_codes/' + zip).done((data) => {
        if(data['state_code']){
          stateInput.val(data['state_code']).trigger('change');
        }
        if(data['city']) {
          cityInput.val(data['city']);
        }
      });
    }
  }
}

$(function() {
  new AutoComplete()
})
