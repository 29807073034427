
const KEYS = {
  left: 37,
  up: 38,
  right: 39,
  down: 40
};

const TABS = '[data-toggle="tab"]';
const ACTIVE_CLASS = 'is-active';

function switchTab(target, moveBy) {
  const tabs = $(target).find(TABS);
  const active = tabs.filter(`.${ACTIVE_CLASS}`);
  const activeTabIndex = tabs.index(active);

  let nextIndex = activeTabIndex + moveBy;

  if (nextIndex > activeTabIndex && nextIndex >= tabs.length) {
    nextIndex = 0;
  } else if (nextIndex < activeTabIndex && nextIndex < 0) {
    nextIndex = tabs.length - 1;
  }

  const tabToShow = $(tabs[nextIndex]);
  tabToShow.tab('show').trigger('focus');
}

$(document).on('keydown', '[data-tabs-navigation]', function(event) {
  switch (event.keyCode) {
    case KEYS.left:
    case KEYS.up: {

      switchTab(event.currentTarget, -1);
      return false;
    }
    case KEYS.right:
    case KEYS.down: {

      switchTab(event.currentTarget, 1);
      return false;
    }
  }

  return true;

});

$(document).on('show.bs.tab', '[data-tabs-navigation]', function(event) {
  $(event.currentTarget).find(TABS).attr('tabindex', -1);
  $(event.target).attr('tabindex', 0);
});