import InteractiveQuiz from 'javascripts/consumer/components/interactive_quiz'
import { getUrlParameter } from 'javascripts/consumer/utils/navigation'

const PLAN_SELECTOR = '.js-plan-select__plan-type, .js-plan-select__plan'
const PLAN_RECIPES = '.js-plan-recipes'

export default class Plans {
  constructor(page) {
    this.page = page
    this.bind()
  }

  bind() {
    const handler = (event) => { this._showRecipes(this._planFor(event)) }
    this.page.find(PLAN_SELECTOR).click(handler)

    $('.js-plan-link').on('click', this._jumpToRecipes.bind(this))
  }

  _showRecipes(plan) {
    if (!plan) return

    $(`${PLAN_RECIPES}`).addClass('d-none')
    $(`${PLAN_RECIPES}[data-plan-group="${plan}"]`).removeClass('d-none')
  }

  _planFor(event) {
    const target = $(event.currentTarget)
    let plan = target.data('plan-group') || $(event.target).data('plan-group')
    const planType = target.data('plan-type')

    if (!plan && planType) {
      plan = $(`[data-plan-type="${planType}"] [data-plan-group].is-selected`).data('plan-group')
    }

    return plan
  }

  _jumpToRecipes(event) {
    const recipesSection = $($(event.currentTarget).data('target'))
    this._showRecipes(recipesSection.data('plan'))
  }
}

$(function() {
  $('.js-plans-page').each(function(index, element) {
    new Plans($(element))

    if (gon.enable_interactive_quiz || (history.state && history.state.step)) {
      new InteractiveQuiz()
    }
  })

  // track vwo exit survey
  window.onmessage = function (e) {
    if( typeof e.data !== 'undefined'){
      if (e.data.action === "submit" || e.data.action === "complete") {
        const data = {
          survey_id: e.data.e,
          question_id: e.data.qIId,
          response: e.data.responses[e.data.qId][0],
          prepared_enabled: !!$('.js-plans-page.feature-prepared').length
        }
        heap.track('vwo_survey_question_answered', data)
      } else if (e.data.action === "surveyCompleted" ) {
        const data = {
          survey_id: e.data.e,
          prepared_enabled: !!$('.js-plans-page.feature-prepared').length
        }
        heap.track('vwo_survey_completed', data)
      }
    }
  }
})
