import { AccordionFlowStep } from 'javascripts/consumer/components/accordion_flow/step'
import TogglePanel from 'javascripts/consumer/components/toggle_panel'
import Recaptcha from 'javascripts/consumer/components/recaptcha'
import ExpressCheckOut from './express_check_out'

const SIGN_IN_CLASSES = 'is-user-signed-in is-user-subscription-status-inactive'

export default class AccountStep extends AccordionFlowStep {
  constructor(container, index) {
    super(container, index)
    this.isLoggedIn = this.container.data('loggedIn')
    this.signupLogin = new TogglePanel(this.container)
    new ExpressCheckOut(this.container)

    this.renderCaptchas()
    this.bind()
  }

  bind() {
    this.container.on('ajax:success', '.js-signups__sign-up-form, .js-signups__log-in-form', this.signIn.bind(this))
    this.container.on('fragment:updated', this.reInitializeFragment.bind(this))
  }

  canComplete() {
    return this.isLoggedIn
  }

  onActivated(stateWas) {
    if (stateWas !== 'completed') return
    this.logOut()
  }

  signIn(event) {
    let [_body, _status, xhr] = event.detail;
    const location = xhr.getResponseHeader('Location');
    if (location) {
      window.location.href = location;
      return
    }

    $(document.body).removeClass('is-user-signed-out').addClass(SIGN_IN_CLASSES);
    this.isLoggedIn = true;

    this.completeStep();
  }

  logOut() {
    this.isLoggedIn = false
    this.signupLogin.activatePanel()
    $.post(this.container.data('destroyPath'), { '_method': 'delete' })
    $(document.body).removeClass(SIGN_IN_CLASSES).addClass('is-user-signed-out')
    $('.js-signups__plan-select-modal-trigger').addClass('d-none')
  }

  renderCaptchas(container = this.container) {
    new Recaptcha(container)
  }

  reInitializeFragment(event, _oldElement, newElement, data) {
    this.renderCaptchas($(event.target))


    if (data.xhr.status == 200 && newElement.data('fragment') == 'sign-up-steps-account-completed') {
      new ExpressCheckOut(this.container)
    }
  }
}
