import InteractiveQuizSteps from './interactive_quiz_steps'
import { getUrlParameter } from 'javascripts/consumer/utils/navigation'

const QUIZ_MODAL = '.js-quiz'

export default class InteractiveQuiz {

  constructor() {
    this.signUpModal = $('.js-signup-login-modal')
    this.bind()
  }

  bind() {
    if (getUrlParameter('signup') == 'modal') {
      this.signUpModal.on('hidden.bs.modal', this.triggerQuiz.bind(this))

    } else if (getUrlParameter('quiz') == 'retake' || (history.state && history.state.step)) {
      this.showQuizModal()

    } else {
      this.triggerQuiz()
    }
  }

  triggerQuiz() {
    setTimeout(this.showQuizModal.bind(this), 4000)
  }

  showQuizModal() {
    if (this.signUpModal.is('.show')) {
      this.signUpModal.on('hidden.bs.modal', this.triggerQuiz.bind(this))
    } else {
      new InteractiveQuizSteps($(QUIZ_MODAL))
    }
  }
}
