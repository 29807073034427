import Rails from '@rails/ujs'

export default class InputToogle {
  constructor(input) {
    this.input = input
  }

  toggleAndSubmit() {
    const newState = this.input.prop('checked')

    this.input.parents('.js-input-toggleable')
      .trigger('input-toggle:change', newState)
      .toggleClass('is-disabled', !newState)

    const enableLabel = this.input.data('skip-enable-label')
    const disableLabel = this.input.data('skip-disable-label')

    if(enableLabel && disableLabel) {
      const label = newState ? enableLabel : disableLabel
      this.input.attr('aria-label', label)
    }
    let $form = this.input.closest('form');
    if ($form.data('remote')) {
      Rails.fire($form[0], 'submit');
    } else {
      $form.trigger('submit');
    }
  }

  markAsReadonly() {
    this.input.on('click', () => { return false })
  }
}

$(document).on('change', '.js-input-toggle', function() {
  new InputToogle($(this)).toggleAndSubmit()
})
