const INPUT = '.js-input-list-autocomplete'
const HIDDEN_CLASS = 'is-hidden'
const SELECTED_CLASS = 'is-selected'
const NO_RESULTS_SELECTOR = '.js-input-list-autocomplete__no-results'
export const ITEM_SELECTOR = '.js-input-list-autocomplete__item'
const KEY_CODES = {
  BACKSPACE: 8,
  RETURN: 13,
  ESC: 27,
  UP: 38,
  DOWN: 40
}

export default class InputListAutocomplete {
  constructor(wrapper, options = {}) {
    this.wrapper = wrapper
    this.listbox = this.wrapper.find('ul')
    this.input = this.wrapper.find('input')
    this.activeIndex = 0
    this.resultsCount = 0
    this.setInputOnSelect = options.setInputOnSelect

    this._bind()
  }

  async searchBy(searchString, shouldShowAll) {
    let results = [];

    if (searchString.length == 0 && !shouldShowAll) return results
    this.searchByList = this.searchByList || this.listbox.find(ITEM_SELECTOR).map(function() { return $(this).text() })

    for (let i = 0; i < this.searchByList.length; i++) {
      let item = this.searchByList[i].toLowerCase();

      if (item.indexOf(searchString.toLowerCase()) === 0) {
        results.push(i);
      }
    }

    return results;
  }

  _bind() {
    this.input.on('focus', () => { this.wrapper.addClass('is-active') })
    this.input.on('blur', this._hide.bind(this))
    this.input.on('keyup', this._setActiveItem.bind(this));
    this.input.on('keydown', this._checkKey.bind(this));
    this.listbox.on('mousedown', ITEM_SELECTOR, (event) => { event.preventDefault(); this._selectItem(event.target) })
    this.listbox.on('mouseover', ITEM_SELECTOR, this._markAsHovered.bind(this))
  }

  _hide() {
    this.wrapper.removeClass('is-active')
    this._resetSearch()
  }

  _resetSearch() {
    this.resultsCount = 0
    this.activeIndex = 0
    if (!this.setInputOnSelect) this.input.val('');
    this.input.attr('aria-activedescendant', '')

    this._listItems.addClass(HIDDEN_CLASS).removeClass(SELECTED_CLASS).prop('aria-selected', false)
    this.listbox.removeClass('is-active')
  }

  async _updateResults(shouldShowAll) {
    const searchString = this._searchString
    let results = await this.searchBy(searchString, shouldShowAll)

    if (!shouldShowAll && !searchString) {
      results = [];
    }

    const items = this._listItems
    items.addClass(HIDDEN_CLASS).removeClass(SELECTED_CLASS).prop('aria-selected', false)

    if (results.length) {
      for (let i = 0; i < results.length; i++) {
        $(items[results[i]]).removeClass(HIDDEN_CLASS)
      }

      this._markSelectedItem($(items[results[this.activeIndex]]))
    }

    const showNoResults = results.length <= 0 && searchString.length > 0
    this.listbox.toggleClass('is-active', results.length > 0 || showNoResults)
    this._toggleNoResults(showNoResults)
    this.resultsCount = results.length
  }

  get _listItems() {
    return this.listbox.find(ITEM_SELECTOR)
  }

  _markSelectedItem(item) {
    item.addClass(SELECTED_CLASS).prop('aria-selected', true)
    this._scrollToVisible(item[0])
    this.input.attr('aria-activedescendant', item.attr('id'))
  }

  _markAsHovered(event) {
    const prevIndex = this.activeIndex
    this.activeIndex = this.listbox.find(`${ITEM_SELECTOR}:visible`).index(event.target)
    this._movePosition(prevIndex)
  }

  _movePosition(prevIndex) {
    if (this.resultsCount < 1) {
      this._updateResults(true)
    } else {
      const results = this.listbox.find(`${ITEM_SELECTOR}:visible`)
      $(results[prevIndex]).removeClass(SELECTED_CLASS).prop('aria-selected', false)
      this._markSelectedItem($(results[this.activeIndex]))
    }
  }

  _toggleNoResults(show) {
    const noResults = this.wrapper.find(NO_RESULTS_SELECTOR)
    noResults.toggleClass(HIDDEN_CLASS, !show)
    this.wrapper.toggleClass('is-invalid', show)
    if (show) this.input.attr('aria-activedescendant', noResults.attr('id'))
  }

  _setActiveItem(event) {
    const key = event.which || event.keyCode;
    const maxIndex = Math.max(0, this.resultsCount - 1)
    const prevIndex = this.activeIndex;

    switch (key) {
      case KEY_CODES.UP:
        this.activeIndex = this.activeIndex <= 0 ? maxIndex : this.activeIndex - 1
        this._movePosition(prevIndex)
        break;
      case KEY_CODES.DOWN:
        this.activeIndex = this.activeIndex >= maxIndex ? 0 : this.activeIndex + 1
        this._movePosition(prevIndex)
        break;
      case KEY_CODES.ESC:
        this._resetSearch()
        return;
      case KEY_CODES.RETURN:
        event.preventDefault()
        this._checkSelection()
        return;
      default:
        this.activeIndex = 0
        this._updateResults(false);
        return;
    }
  }

  _checkSelection() {
    if (this.resultsCount <= 0) return
    this._selectItem(this.listbox.find(`${ITEM_SELECTOR}:visible`)[this.activeIndex])
    this._resetSearch()
  }

  _selectItem(element) {
    if (!element) return
    if (this.setInputOnSelect) this.input.val($(element).text());
    this.wrapper.trigger('list_autocomplete:selected', element)
    this._resetSearch()
  }

  _checkKey(event) {
    if ([KEY_CODES.UP, KEY_CODES.DOWN, KEY_CODES.ESC, KEY_CODES.RETURN].indexOf(event.which || event.keyCode) >= 0) {
      event.preventDefault();
      return;
    }
  }

  _scrollToVisible(target) {
    const selectList = this.listbox[0]
    const itemPosition = target.getBoundingClientRect()
    const listPosition = selectList.getBoundingClientRect()

    if (itemPosition.top < listPosition.top) selectList.scrollTop = selectList.scrollTop - (listPosition.top - itemPosition.top)
    if (itemPosition.bottom > listPosition.bottom) selectList.scrollTop = selectList.scrollTop + (itemPosition.bottom - listPosition.bottom)
  }

  get _searchString() {
    return this.input.val()
  }
}

$(function() {
  $(INPUT).each(function() {
    new InputListAutocomplete($(this))
  })
})
