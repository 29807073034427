import AlternativePaymentButton from './alternative_payment_button'

const OPTIONS = {
  env: BRAINTREE_PAYPAL_ENV,
  commit: false, // This will make paypal button show 'Agree and Continue' instead of 'Agree and Pay'
  locale: 'en_US',
  style: {
    size: 'responsive',
    color: 'gold',
    shape: 'rect',
    label: 'paypal',
    tagline: false
  },
  onCancel: function (data) {}, // do nothing on cancel
  onError: function (err) {
    alert('There was an error contacting Paypal. Please try again or contact Purple Carrot support')
  }
}

const BUTTON = '.js-paypal-button'
const PROVIDER = 'Braintree'

export default class PaypalButton extends AlternativePaymentButton {
  constructor(container, addressForm) {
    super(container, addressForm, BUTTON)
    this.paypalCheckoutInstance = null
    this.spinner = this.container.find('.js-paypal-button__spinner')
  }

  renderButton() {
    BraintreeClient.paypalLoaded().then(this._render.bind(this))
  }

  _render(paypalCheckoutInstance) {
    this.paypalCheckoutInstance = paypalCheckoutInstance
    window.paypal.Button.render(
      $.extend({},
        OPTIONS,
        {
          payment: this._payment.bind(this),
          onAuthorize: this._onAuthorize.bind(this),
          funding: {
            allowed: [ paypal.FUNDING.CARD ],
            disallowed: [ paypal.FUNDING.CREDIT ]
          }
        }),
        this.button[0]
    )
  }

  _payment() {
    let enableShippingAddress = this.button.data('enable-shipping-address')
    enableShippingAddress = (enableShippingAddress === undefined) ? true : enableShippingAddress

    return this.paypalCheckoutInstance.createPayment(
      $.extend(
        {
          flow: 'vault',
          enableShippingAddress: enableShippingAddress
        },
        this._addressAttributes
    ));
  }

  _onAuthorize(data, actions) {
    return this.paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
      this.toggleProcessing(true)
      this.fillInNonceAndSubmit(payload.nonce, PROVIDER, this._normalizeAddress(payload))
    });
  }

  _normalizeAddress(payload) {
    const address = payload.details.shippingAddress || {}
    const phone = (payload.details.phone || '').replace(/\D+/g, '')
    const recipientName = (address.recipientName || `${payload.details.firstName} ${payload.details.lastName}`).split(' ')

    address.phone = phone
    address.firstName = recipientName[0]
    address.lastName = recipientName[1]

    return address
  }

  get _addressAttributes() {
    if (!this.addressForm) return {};

    return {
      shippingAddressEditable: false,
      shippingAddressOverride: {
        recipientName: this.addressForm.fullName,
        line1: this.addressForm.line1.val(),
        line2: this.addressForm.line2.val(),
        city:  this.addressForm.city.val(),
        countryCode: "US",
        postalCode: this.addressForm.zip.val(),
        state: this.addressForm.state.val(),
        phone: this.addressForm.phone.val()
      }
    }
  }
}
