const SKU_IMAGE_LINK = '.js-sku-image-modal'

class Skus {
  constructor() {
    this.bind()
  }

  bind() {
    $(SKU_IMAGE_LINK).on('click', function(e){
      let index = $(e.currentTarget).data('index')
      let imageCarouselItem = $($(e.currentTarget).data('target')).find('.carousel-item')

      imageCarouselItem.removeClass('active')
      $(imageCarouselItem[index]).addClass('active')

      $($(e.currentTarget).data('target')).modal('show');
    })
  }
}


$(function() {
  new Skus()
})
