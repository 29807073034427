const GALLERY = '.js-recipe-gallery';
const PREV_STEP = `${GALLERY}__prev`;
const NEXT_STEP = `${GALLERY}__next`;
const RECIPE = `${GALLERY}__recipe`;
const FIRST_RECIPE_MARGIN_LEFT = 35;
const DISABLED = 'is-disabled';

class Recipe {
  constructor(element) {
    this.element = element
    const { x, width } = this.element.getBoundingClientRect();

    this.leftPoint = x;
    this.width = width;
  }

  isVisible({from, to}) {
    const rightPoint = this.leftPoint + this.width;

    return this.leftPoint >= from  && rightPoint <= to;
  }
}

class RecipesGallery {
  constructor(gallery) {
    this.gallery = gallery;
    this.recipes = this.gallery.find(RECIPE);
    this.prevStep = this.gallery.find(PREV_STEP);
    this.nextStep = this.gallery.find(NEXT_STEP);

    this._bind();
    this._toggleNavigation()
  }

  _bind() {
    this.gallery.on('click', PREV_STEP, this._scrollBack.bind(this));
    this.gallery.on('click', NEXT_STEP, this._scrollForward.bind(this));
    $(window).on('resize', this._toggleNavigation.bind(this));
    this.gallery.on('scroll', this._toggleNavigation.bind(this));
  }

  _toggleNavigation() {
    const { scrollLeft, clientWidth } = this.gallery[0];
    const recipeWidth = $(this.recipes[1]).outerWidth(true);
    const firstIsVisible = (scrollLeft >= 0 && scrollLeft <= (recipeWidth * 0.2) + FIRST_RECIPE_MARGIN_LEFT);
    const lastIsVisible = (recipeWidth * (this.recipes.length - 1) + (recipeWidth * 0.8)) < (scrollLeft + clientWidth);

    this.prevStep.toggleClass(DISABLED, firstIsVisible);
    this.nextStep.toggleClass(DISABLED, lastIsVisible);
  }

  _scrollBack(event) {
    if ($(event.currentTarget).is(`.${DISABLED}`)) return;

    this._findAndScrollTo(({recipe, galleryLeftPoint, galleryWidth}) => {
      return recipe.isVisible({
        from: galleryLeftPoint - galleryWidth,
        to:  galleryLeftPoint + (recipe.width * 0.8)
      });
    });
  }

  _scrollForward(event) {
    if ($(event.currentTarget).is(`.${DISABLED}`)) return;

    this._findAndScrollTo(({recipe, galleryLeftPoint, galleryWidth}) => {
      const isVisibleForward = recipe.leftPoint > galleryLeftPoint;

      return isVisibleForward &&
        !recipe.isVisible({
          from: galleryLeftPoint,
          to:  galleryLeftPoint + galleryWidth + (recipe.width * 0.2)
        });
    });
  }

  _findAndScrollTo(findBy) {
    const { x: galleryLeftPoint, width: galleryWidth } = this.gallery[0].getBoundingClientRect();

    const recipe = this.recipes
      .map((_, recipe) => { return new Recipe(recipe) })
      .toArray()
      .find((recipe) => findBy.apply(this, [{ recipe: recipe, galleryLeftPoint, galleryWidth }]));

    if (recipe) {
      this._scrollTo({ recipe, galleryLeftPoint });
    }
  }

  _scrollTo({ recipe, galleryLeftPoint }) {
    const { scrollLeft } = this.gallery[0];
    const scrollToPoint = (scrollLeft + recipe.leftPoint - galleryLeftPoint - FIRST_RECIPE_MARGIN_LEFT);

    this.gallery[0].scrollLeft = scrollToPoint;
  }
}

$(function() {
  $(GALLERY).each(function() {
    new RecipesGallery($(this));
  });
});