import { closeWebview } from '../flex_box/utils/webview';

window.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', e => {
    if (e.target && (e.target.getAttribute('role') === 'close-webview' || e.target.closest('[role="close-webview"]'))) {
      e.preventDefault();
      closeWebview();
    }
  });
});
