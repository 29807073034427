const NONCE_INPUT = '.js-payment-method__nonce'
const PROVIDER_INPUT = '.js-payment-method__provider'
const FORM = '.js-payment-method__form'
import Rails from '@rails/ujs'

export class AddressForm {
  constructor(container) {
    this.container = container
  }

  fillInWith(info) {
    const address = info.address || {}

    this.firstName.val(address.firstName)
    this.lastName.val(address.lastName)
    this.phone.val(address.phone)
    this.line1.val(address.line1)
    this.line2.val(address.line2)
    this.city.val(address.city)
    this.zip.val(address.postalCode)
    this.state.val(address.state)
    this.token.val(info.nonce)
    this.provider.val(info.provider)
  }

  get firstName() {
    return this.container.find('.js-address-first-name')
  }

  get lastName() {
    return this.container.find('.js-address-last-name')
  }

  get fullName() {
    return [this.firstName.val(), this.lastName.val()].join(' ')
  }

  get line1() {
    return this.container.find('.js-address-line-1')
  }

  get line2() {
    return this.container.find('.js-address-line-2')
  }

  get zip() {
    return this.container.find('.js-address-zip')
  }

  get state() {
    return this.container.find('.js-address-state')
  }

  get city() {
    return this.container.find('.js-address-city')
  }

  get phone() {
    return this.container.find('.js-address-phone')
  }

  get token() {
    return this.container.find('.js-payment-method__nonce')
  }

  get provider() {
    return this.container.find('.js-payment-method__provider')
  }
}

export default class AlternativePaymentButton {
  constructor(container, addressForm, buttonSelector) {
    this.container = container
    this.onAuthorize = new $.Deferred
    this.button = this.container.find(buttonSelector)

    if (!this.button.length) return;

    this.enabled = false
    this.loaded = true
    this.renderButton()
    if (addressForm) { this.addressForm = new AddressForm(addressForm) }
  }

  set enabled(value) {
    this._enabled = value
    this.container.find('[name^="user[payment_method_attributes]"]').attr('disabled', !value)

    return this._enabled;
  }

  get enabled() {
    return this._enabled;
  }

  set loaded(value) {
    this._loaded = value

    if (!this.onLoad) {
      this.onLoad = new $.Deferred
      return this._loaded
    }

    if (this._loaded) {
      this.button.removeClass('d-none')
      this.onLoad.resolve();
    }

    return this._loaded;
  }

  get loaded() {
    return this._loaded;
  }

  fillInNonceAndSubmit(nonce, provider, address) {
    const form = this.container.closest('.js-payment-methods').find(FORM)

    const disableSubmit = form.data('disable-submit')
    form.find(NONCE_INPUT).val(nonce)
    form.find(PROVIDER_INPUT).val(provider)

    if (form.submit && !disableSubmit) {
      this.onAuthorize.then(submitForm => submitForm ? Rails.fire(form[0], 'submit') : null)
    }

    this.onAuthorize.resolve({ address: address, provider: provider, nonce: nonce }, form)
  }

  onToggleProcessing(callback) {
    this.toggleProcessingCallback = callback
  }

  toggleProcessing(enabled) {
    this.button.toggleClass('d-none', enabled)
    if (this.spinner) this.spinner.toggleClass('d-none', !enabled)

    if (this.toggleProcessingCallback) {
      this.toggleProcessingCallback.apply(this, [enabled])
    }
  }
}
