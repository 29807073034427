$.fn.deserialize = function(data)
{
  this.each(function() {
    deserialize(this, data);
  });
};

function deserialize(element, data) {
  var splits = decodeURIComponent(data).split('&'),
    i = 0,
    split = null,
    key = null,
    value = null,
    splitParts = null;

  var kv = {};
  while(split = splits[i++]){
    splitParts = split.split('=');
    key = splitParts[0] || '';
    value = (splitParts[1] || '').replace(/\+/g, ' ');

    if (key != ''){
      if( key in kv ){
        if( $.type(kv[key]) !== 'array' )
          kv[key] = [kv[key]];

        kv[key].push(value);
      } else
        kv[key] = value;
    }
  }

  for( key in kv ){
    value = kv[key];

    $('input[type="checkbox"][name="'+ key +'"][value="'+ value +'"],input[type="radio"][name="'+ key +'"][value="'+ value +'"]', element).prop('checked', true);
    $('select[name="'+ key +'"],input[type="text"][name="'+ key +'"],input[type="tel"][name="'+ key +'"],input[type="email"][name="'+ key +'"],input[type="password"][name="'+ key +'"],input[type="hidden"][name="'+ key +'"],textarea[name="'+ key +'"]', element).val(value).change();
  }
}
