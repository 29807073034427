const INPUT_TICKER_SELECTOR = '.js-input-ticker'
const STEP_DOWN = '.js-input-ticker__step-down'
const STEP_UP = '.js-input-ticker__step-up'

class InputTicker {
  constructor(tickerInput) {
    this.tickerInput = tickerInput
    this.input = tickerInput.find('[type="number"]')
    this.stepDown = tickerInput.find(STEP_DOWN)
    this.stepUp = tickerInput.find(STEP_UP)

    this._bind()
  }

  _bind() {
    this.stepDown.on('click', this._goStepDown.bind(this))
    this.stepUp.on('click', this._goStepUp.bind(this))
    this.input.on('change', this._toggleActiveUpAndDown.bind(this))
    this._toggleActiveUpAndDown()
  }

  _toggleActiveUpAndDown() {
    this.stepDown.attr('disabled', this._value <= this._min)
    this.stepUp.attr('disabled', this._value >= this._max)
  }

  get _min() {
    const min = parseInt(this.input.attr('min'))
    return isNaN(min) ? -Infinity : min;
  }

  get _max() {
    const max = parseInt(this.input.attr('max'))
    return isNaN(max) ? Infinity : max;
  }

  get _value() {
    return parseInt(this.input.val())
  }

  get _step() {
    return parseInt(this.input.attr('step')) || 1;
  }

  _goStepUp() {
    let newValue = this._value + this._step;
    if (newValue <= this._max) this.input.val(newValue).trigger("change");

    this._toggleActiveUpAndDown()
  }

  _goStepDown() {
    const newValue = this._value - this._step;
    if (newValue >= this._min) this.input.val(newValue).trigger("change");

    this._toggleActiveUpAndDown()
  }
}

$(function() {
  $(INPUT_TICKER_SELECTOR).each(function() {
    new InputTicker($(this))
  })
})
