import Recaptcha from 'javascripts/consumer/components/recaptcha'
import AccordionFlow from 'javascripts/consumer/components/accordion_flow'
import PlanStep from './plan_step'
import DetailsStep from './details_step'
import PaymentStep from './payment_step'

$(function() {
  const giftsFlowContainer = $('.js-gifts-flow')
  if (giftsFlowContainer.length === 0) return

  $('.js-signups__side-unit').find('[data-toggle="popover"]').popover()

  const giftsFlow = new AccordionFlow(
    giftsFlowContainer,
    { 'plan': PlanStep, 'details': DetailsStep, 'payment': PaymentStep }
  )

  giftsFlowContainer.on('submit', () => {
    if (giftsFlow.isLastStep()) return true;

    giftsFlow.completeStep();

    return false
  })

  giftsFlowContainer.on('ajax:error', () =>
    giftsFlow.activeStep.trigger('form:submit:error')
  )

  giftsFlowContainer.on('recaptcha', () => {
    new Recaptcha(giftsFlowContainer)
  })

  new Recaptcha(giftsFlowContainer)
})
