import { AddressForm } from 'javascripts/consumer/components/alternative_payment_button'
import PaypalButton from 'javascripts/consumer/components/paypal_button'
import GooglePayButton from 'javascripts/consumer/components/google_pay_button'
import ApplePayButton from 'javascripts/consumer/components/apple_pay_button'
import { price } from 'javascripts/consumer/components/payment_form'
import Rails from '@rails/ujs'

const WEEKLY_SUBSCRIPTION_AGREEMENT = '#payment-method-weekly-subscription-agreement';
const PAYMENT_METHOD_SUBMIT = '#payment-method-submit';

export default class ExpressCheckOut {
  constructor(container) {
    this.container = container

    this.paymentMethods = [
      new PaypalButton(this.container),
      new GooglePayButton(this.container),

      // Disabled because of the signup issue with express checkout
      // new ApplePayButton(this.container, {}, price())
    ]

    this.bind()
  }

  bind() {
    this.paymentMethods.forEach((paymentMethod) => {
      paymentMethod.onAuthorize.then(this._fillInAddressForm.bind(this))
      paymentMethod.onToggleProcessing(this._hidePaymentMethods.bind(this))
    })
    this.container.parent().on('change', WEEKLY_SUBSCRIPTION_AGREEMENT, this._toggleSubmitButton.bind(this))
    this._toggleSubmitButton()
  }

  _fillInAddressForm(info) {
    const addressForm = $('.js-payment-address-form')
    new AddressForm(addressForm).fillInWith(info)
    Rails.fire(addressForm[0], 'submit');

    return false;
  }

  _hidePaymentMethods(enabled) {
    this.paymentMethods.forEach((paymentMethod) => {
      paymentMethod.button.toggleClass('d-none', enabled)
    })
  }

  /**
   * Disables submit button if WEEKLY_SUBSCRIPTION_AGREEMENT is not checked and vice-versa
   *
   * @private
   */
  _toggleSubmitButton() {
    const agreementChecked = this.container.parent().find(WEEKLY_SUBSCRIPTION_AGREEMENT).prop('checked')
    const paymentMethodSubmitButton = this.container.parent().find(`${PAYMENT_METHOD_SUBMIT} button`)
    paymentMethodSubmitButton.toggleClass('c-button--spinner', agreementChecked).attr('disabled', !agreementChecked)
    paymentMethodSubmitButton.find('.c-button__spinner').toggleClass('d-none', !agreementChecked)
  }
}
