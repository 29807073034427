import 'jquery-mask-plugin'
import PaymentFormValidation from 'javascripts/consumer/components/payment_form_validation'
import BraintreeClient from 'javascripts/consumer/components/braintree_client'
import Rails from '@rails/ujs'

const FORM_INPUTS = function() {
  return `
    <input type="hidden" name="user[payment_method_attributes][token]" value="${this.response.nonce}"/>
    <input type="hidden" name="user[payment_method_attributes][cc_last_four]" value="${this.response.details.lastFour}"/>
    <input type="hidden" name="user[payment_method_attributes][cc_brand]" value="${this.response.details.cardType}"/>
    <input type="hidden" name="user[payment_method_attributes][cc_exp_month]" value="${this.form.month.val()}"/>
    <input type="hidden" name="user[payment_method_attributes][cc_exp_year]" value="${this.form.year.val()}"/>
    <input type="hidden" name="user[payment_method_attributes][provider_name]" value="${this.form.data('provider')}"/>
  `
}
const ERROR_ALERT = function() {
  return `
    <div class="c-alert c-alert--error" role="alertdialog" aria-describedby="alert-credit-card-error-notification" tabindex="-1">
      <div id="alert-credit-card-error-notification" class="c-alert__text">
        ${this.error}
      </div>
    </div>
  `
}

class Form {
  constructor(element) {
    this.element = element
    this.on = this.element.on.bind(this.element)
    this.one = this.element.one.bind(this.element)
    this.append = this.element.append.bind(this.element)
    this.data = this.element.data.bind(this.element)
  }

  get number() {
    return this._number = this._number || this.element.find('[data-credit-card="number"]')
  }

  get month() {
    return this._month = this._month || this.element.find('[data-credit-card="exp-month"]')
  }

  get year() {
    return this._year = this._year || this.element.find('[data-credit-card="exp-year"]')
  }

  get cvc() {
    return this._cvc = this._cvc || this.element.find('[data-credit-card="cvc"]')
  }
}

export default class CreditCardForm {
  static get inputsTemplate() {
    return FORM_INPUTS;
  }

  constructor(container, enabled = false) {
    this.container = container;
    this.form = new Form(container);
    this.form.number.mask('0000 0000 0000 0000');
    this.enabled = enabled;
    this.loaded = true;
    this.generatedToken = false;

    this.bind()
  }

  bind() {
    this.form.on('submit', this.createToken.bind(this))
  }

  createToken() {
    if (!this.enabled || this.generatedToken) return true;

    new PaymentFormValidation(this.form).isValid().then(() => {
      (new BraintreeClient).createToken({ creditCard: this.cardParams }, this.responseHandler.bind(this))
    }, (_form, message) => {
      this.showError(message);
    })

    return false
  }

  get cardParams() {
    return {
      number: this.form.number.val(),
      expirationMonth: this.form.month.val(),
      expirationYear: this.form.year.val(),
      cvv: this.form.cvc.val()
    }
  }

  showError(error) {
    const errorAlert = $(ERROR_ALERT.apply({ error: error }))
    $('.js-credit-card__form-errors').html(errorAlert);
    this.form.element.find('[autofocus]').focus()
  }

  responseHandler(error, response) {
    if (error) {
      this.showError(error.details.originalError.fieldErrors[0].fieldErrors[0].message)
    } else {
      this.disableFields()
      this.form.append(this.constructor.inputsTemplate.apply({ response: response.creditCards[0], form: this.form }))
      this.generatedToken = true;
      this.submitForm()
    }
  }

  disableFields(disabled = true) {
    this.form.element.find('[data-credit-card]').prop('disabled', disabled)
  }

  submitForm() {
    if(this.form.data('remote')) {
      this.form.one('ajax:complete', this.disableFields.bind(this, false))
      Rails.fire(this.container[0], 'submit');
    } else {
      this.container[0].submit();
    }
  }
}
