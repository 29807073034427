const SELECT_VALUE_HEIGHT = 40
const ITEM_SELECTOR = '.js-select__item'
const HOVERABLE_ITEM = ITEM_SELECTOR + ', .js-select__cancel-button'
const TEMPLATE = function() {
  const item = this.select[0].options[this.select[0].selectedIndex] || this.select[0].options[0]

  return `
    <div class="c-select__value js-select__value ${this.isBlankClass}" role="option" aria-selected="${!this.isBlank}">
      ${new SelectItem(this.value, item, 'label-0').innerHTML}
    </div>

    <div class="c-select__list js-select__list">
      ${this.selectItems()}
      ${this.closeSelectItem()}
    </div>
  `
}

function isComponentActivated(element, name) {
  const activated = element.data(`component-${name}`)
  element.data(`component-${name}`, true)

  return activated
}

class SelectItem {
  constructor(selectedValue, item, id) {
    this.selectedValue = selectedValue
    this.item = $(item)
    this.id = id
  }

  render() {
    return `
      <div id="label-${this.id}"
        class="js-select__item c-select__item-wrapper ${this.isDisabled} ${this.isSelectedClass}"
        data-value="${this.value}"
        data-price="${this.item.data('price')}"
        role="option"
        aria-selected="${this.isSelected}">
        ${this.innerHTML}
      </div>
    `
  }

  get thumbnailImg() {
    if (!this.item.data('thumbnailImg')) return ''
    return `<div class="c-select__img-wrapper">${this.item.data('thumbnailImg')}</div>`
  }

  get subtitle() {
    if (!this.item.data('subtitle')) return ''
    return `<div class="c-select__subtitle">${this.item.data('subtitle')}</div>`
  }

  get value() {
    return this.item[0].value
  }

  get svgCaret() {
    if (!this.item.data('type')) return ''
    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8" width="15" height="15" ><polygon fill-rule="evenodd" points="727.754 962.008 721.133 955.256 719.744 956.673 724.976 962.009 719.75 967.337 721.139 968.754" transform="rotate(90 844.249 124.505)"/></svg>'
  }

  get innerHTML() {
    return `
      <div class="c-select__item">
        ${this.thumbnailImg}
        <div class="c-select__heading">
          <div class="c-select__title">${this.item[0].innerHTML}${this.svgCaret}</div>
          ${this.subtitle}
        </div>
      </div>
    `
  }

  get isSelected() {
    return (this.selectedValue == this.value)
  }

  get isSelectedClass() {
    return this.isSelected ? 'is-selected' : ''
  }

  get isDisabled() {
    return (!this.isSelected && this.item.is(':disabled')) ? 'is-disabled' : ''
  }
}

const NAVIGATION = ['ArrowUp', 'ArrowDown']
const HOVERED = 'is-hovered'

class Navigatable {
  constructor(select) {
    this.select = select
    this.selectBox = select.selectBox
    this._bind()
  }

  _bind() {
    this.selectBox.on('keydown', this._navigate.bind(this))
    this.selectBox.on('keydown', this._pickSelected.bind(this))
    this.selectBox.on('keydown', this._close.bind(this))
    this.selectBox.on('blur', () => { this.select.toggleActive(false) })
    this.selectBox.on('mouseover', HOVERABLE_ITEM, this._markAsHovered.bind(this))
  }

  _pickSelected(event) {
    if (event.key !== 'Enter') return
    this._selected.click()
  }

  _close(event) {
    if (event.key !== 'Escape') return
    this.select.toggleActive(false)
  }

  _navigate(event) {
    if (NAVIGATION.indexOf(event.key) < 0) return
    event.preventDefault()

    if (!this.select.isActive) {
      this.select.toggleActive(true)
      this._selected.addClass(HOVERED).focus().attr('aria-selected', 'true')
      return
    }

    this._updateSelectedItem(event.key)
  }

  _updateSelectedItem(key) {
    const selected = this._selected
    let nextSelected = null

    if (key === 'ArrowUp') {
      nextSelected = selected.prev()
    } else if (key === 'ArrowDown') {
      nextSelected = selected.next()
    }

    if (nextSelected.length > 0) {
      selected.removeClass(HOVERED).attr('aria-selected', 'false')
      this._scrollToVisible(nextSelected[0])

      nextSelected.addClass(HOVERED).focus().attr('aria-selected', 'true')
      this.selectBox.attr('aria-activedescendant', this._selected.attr('id'))
    }
  }

  _markAsHovered(event) {
    this.selectBox.find(HOVERABLE_ITEM).removeClass(HOVERED).attr('aria-selected', 'false')
    this.selectBox.attr('aria-activedescendant', event.currentTarget.id)
    $(event.currentTarget).addClass(HOVERED).attr('aria-selected', 'true')

    this._scrollToVisible(event.currentTarget)
  }


  _scrollToVisible(target) {
    const selectList = this.selectBox.find('.js-select__list')[0]
    const itemPosition = target.getBoundingClientRect()
    const listPosition = selectList.getBoundingClientRect()

    if (itemPosition.top < listPosition.top) selectList.scrollTop = selectList.scrollTop - (listPosition.top - itemPosition.top)
    if (itemPosition.bottom > listPosition.bottom) selectList.scrollTop = selectList.scrollTop + (itemPosition.bottom - listPosition.bottom)
  }


  get _selected() {
    this.items = this.selectBox.find(HOVERABLE_ITEM)
    let selected = this.items.filter(`.${HOVERED}:first`)[0]
    selected = selected || this.items.filter('.is-selected:first')[0]
    selected = selected || this.items[0]

    return $(selected)
  }
}

export default class Select {
  constructor(select) {
    this.select = select
    this.value = this.select.val()

    this.selectBox = this.select.data('select-box') || $(`<div class="c-select ${this.selectClasses}" tabindex="0" aria-expanded="false" role="listbox" ${this.ariaAttributes}></div>`)
    this.select.data('select-box', this.selectBox)

    if (isComponentActivated(this.selectBox, 'select')) return
    this.navigatable = new Navigatable(this)

    this.select.before(this.selectBox)
    this.bind();
    this.render()
  }

  get isCustom() {
    return !!this.select.data('selectCustom')
  }

  get selectClasses() {
    if (!this.select.data('customClass')) return ''
    return this.select.data('customClass')
  }

  get ariaAttributes() {
    let result = ''
    const attributes = ['aria-label', 'aria-labelledby', 'aria-required', 'aria-invalid', 'aria-describedby']

    for (let attribute of attributes) {
      let value = this.select.attr(attribute)
      if (value) result += `${attribute}="${value}" `
    }

    return result
  }

  get _selected() {
    this.items = this.selectBox.find(HOVERABLE_ITEM)
    let selected = this.items.filter('.is-selected:first')[0]
    selected = selected || this.items[0]

    return $(selected)
  }

  bind() {
    this.select.on('change', (event) => { this.setValue(event.target.value) })
    this.selectBox.on('click', ITEM_SELECTOR, (event) => { this.customItemSelected($(event.currentTarget).data('value')) })
    this.selectBox.on('click', '.js-select__value, .js-select__ce-button', this.toggleActive.bind(this))
    this.selectBox.on('click', '.js-select__cancel-button', () => { this.customItemSelected('') })
    $(document).on('click', this.closeOnOutSideClick.bind(this))
  }

  render() {
    this.selectBox.html($(TEMPLATE.apply(this)))
    this.selectBox.toggleClass('is-blank', this.value === '')
    this.selectBox.attr('disabled', !!this.select.attr('disabled'))
    if (!this.isBlank) {
      this.selectBox.attr('aria-activedescendant', this._selected.attr('id'))
    }
  }

  selectItems() {
    let items = ''

    Array.prototype.forEach.call(this.select[0].options, (option, index) => {
      if (option.disabled || !option.value || option.value.length == '') return;
      items += new SelectItem(this.value, option, `${this.select.attr('id')}-${index + 1}`).render()
    })

    return items;
  }

  customItemSelected(value) {
    const valueWas = this.value
    this.setValue(value)
    if (valueWas != this.value) this.select.trigger('change')

    return false
  }

  setValue(value) {
    if(this.value != value) {
      this.value = value
      this.select.val(this.value)
      this.toggleActive(false)
    }
    this.render()
  }

  toggleActive(toggle = this.selectBox.is('.is-active')) {
    if (this.select.attr('disabled')) return
    if (!toggle) this.selectBox.find(HOVERABLE_ITEM).removeClass(HOVERED)

    this.selectBox
      .toggleClass('is-active', toggle)
      .attr('aria-expanded', !!toggle)

    this.showUp()
  }

  closeOnOutSideClick(event) {
    if ($.contains(this.selectBox[0], event.target)) return;
    this.toggleActive(false)
  }

  showUp() {
    if (this.isActive) {
      const selectList = this.selectBox.find('.js-select__list').css({top: ''})
      const position = selectList[0].getBoundingClientRect()
      const moveUp = position.height;

      if (position.bottom >= window.innerHeight && (position.top - moveUp - SELECT_VALUE_HEIGHT) > 0) {
        selectList.css({ top: `${(moveUp * -1).toString()}px` })
      }
    }
  }

  get isActive() {
    return this.selectBox.hasClass('is-active')
  }

  get isBlank() {
    return !this.value
  }

  get isBlankClass() {
    return this.isBlank ? 'is-blank' : ''
  }

  closeSelectItem() {
    if (!this.select.data('selectCancelButton')) return ''
    return `<a id="cancel-${this.select.attr('id')}" class="c-select__cancel-button js-select__cancel-button">${this.select.data('selectCancelButton')}</a>`
  }

  toggleValue(value) {
    this.select.find(`option[value="${value}"]`).each((i, option) => {
      if (option.value == this.value) return
      $(option).prop('disabled', !$(option).prop('disabled'))
    })

    this.render()
  }

  hideValue(value) {
    this.select.find(`option[value="${value}"]`).each((i, option) => {
      if (option.value == this.value) return
      $(option).prop('disabled', true)
    })

    this.render()
  }

  showValue(value) {
    this.select.find(`option[value="${value}"]`).each((i, option) => {
      if (option.value == this.value) return
      $(option).prop('disabled', false)
    })

    this.render()
  }
}

export function renderSelects(content = document) {
  $(content).find('[data-select]').each(function(index, element) { new Select($(element)) })
}

$(function() {
  renderSelects()
  $(document).on('fragments:updated', function () { renderSelects() })
})
