const RANGE_SLIDER = '.js-input-range'
const OPEN_QUESTION = '.js-survey__open-question'
const QUESTION_CONTAINER = '.js-survey__question'
const PREVIOUS_BTN = '.js-previous-button'
const NEXT_BTN = '.js-next-button'
const NOT_APPLICABLE = '.js-not-applicable'
const EAT_AGAIN = '.js-eat-again'

export default class CxSurvey {
  constructor(container, forms) {
    this.container = container
    this.forms = forms
    this.previousButtons = container.find(PREVIOUS_BTN)

    this.bind()
  }

  bind() {
    this.container.find(RANGE_SLIDER).on('change', this.onInputRangeActivated.bind(this))
    this.container.find(RANGE_SLIDER).on('click', this.onInputRangeActivated.bind(this))
    this.container.find(NOT_APPLICABLE).on('change', this.onNotApplicableEnabled.bind(this))
    this.forms.bind('ajax:success', this.onFormSubmit.bind(this))
    this.previousButtons.on('click', this.onPreviousButtonPressed.bind(this))

    $(QUESTION_CONTAINER + ':not(.d-none)').find(NOT_APPLICABLE).change()
  }

  // Triggers
  onInputRangeActivated(event) {
    // if all input range are selected enable CTA
    if(this.allRangeInputsSelected()) {
      $(event.currentTarget).closest(QUESTION_CONTAINER).find(NEXT_BTN).prop('disabled', false)
    }
  }

  onNotApplicableEnabled(event) {
    let notApplicable = $(event.currentTarget).prop("checked");

    if(notApplicable){
      this.disableOtherQuestions(event)

      $($(QUESTION_CONTAINER + ':not(.d-none)').find(RANGE_SLIDER + '.is-activated')).each((index, element) => {
        $(element).find('input').val('1').change()
        $(element).removeClass('is-activated')
        $(element).find('.c-input-range__ticks span.js-input-range__tick.is-selected').removeClass('is-selected')
      })

      $(event.currentTarget).closest(QUESTION_CONTAINER).find(OPEN_QUESTION).val('')
    } else {
      this.enableOtherQuestions(event)
    }

    $(event.currentTarget).closest(QUESTION_CONTAINER).find(NEXT_BTN).prop('disabled', !notApplicable && !this.allRangeInputsSelected())
  }

  allRangeInputsSelected() {
    return $(QUESTION_CONTAINER + ':not(.d-none)').find(RANGE_SLIDER + ':not(.is-activated)').length == 0
  }

  onPreviousButtonPressed(event) {
    event.preventDefault()

    this.container.find(QUESTION_CONTAINER).addClass('d-none')
    const prevQuestionContainer = $(event.currentTarget).closest(QUESTION_CONTAINER).prev()
    prevQuestionContainer.removeClass('d-none').find(NEXT_BTN).prop('disabled', false)
    prevQuestionContainer.find(NOT_APPLICABLE).change()
  }

  onFormSubmit(event) {
    this.container.find(QUESTION_CONTAINER).addClass('d-none')
    const nextQuestionContainer = $(event.currentTarget).closest(QUESTION_CONTAINER).next()
    nextQuestionContainer.removeClass('d-none')
    nextQuestionContainer.find(NOT_APPLICABLE).change()

    window.scrollTo(0, $(event.currentTarget).closest(QUESTION_CONTAINER).offset().top)
  }

  disableOtherQuestions(event) {
    $(event.currentTarget).closest(QUESTION_CONTAINER).find(RANGE_SLIDER).each((index, element) => {
      $(element).addClass('c-input-range--disabled rangeslider--disabled')
    })
    $(event.currentTarget).closest(QUESTION_CONTAINER).find(OPEN_QUESTION).prop('disabled', true)
    $(event.currentTarget).closest(QUESTION_CONTAINER).find(EAT_AGAIN).prop('disabled', true)
  }

  enableOtherQuestions(event) {
    $(event.currentTarget).closest(QUESTION_CONTAINER).find(RANGE_SLIDER).each((index, element) => {
      $(element).removeClass('c-input-range--disabled rangeslider--disabled')
    })
    $(event.currentTarget).closest(QUESTION_CONTAINER).find(OPEN_QUESTION).prop('disabled', false)
    $(event.currentTarget).closest(QUESTION_CONTAINER).find(EAT_AGAIN).prop('disabled', false)
  }
}
