/* The component is used for describing the default step behaviour in
 * AccordionFlow component. The step component communicates to
 * AccordionFlow component through passing events. See EVENTS constant.
 *
 * Each step can be overriden by extending the AccordionFlowStep:
 *
 * class ChooseGiftStep extends AccordionFlowStep {
 *   constructor(container, index) {
 *     super(container, index)
 *
 *     this.customAttribute = value
 *   }
 * }
 *
 * each overriden step can handle state changes by overriding
 * canComplete(). The method can return true/false
 * values or instance of $.Deferred object (for async operations).
 * If method returns "false" the state won't be applied and the step
 * won't be completed/activated/in-activated
 *
 * canComplete(newState) {
 *  return this.currentState != newState
 * }
 */
import { scrollToUnlessVisible } from 'javascripts/consumer/utils/scroll_to'

const ACCORDION_FLOW_STEP_BODY = '.js-accordion-step__body'

const CALLBACKS = {
  active: 'onActivated',
  completed: 'onCompleted',
  inactive: 'onDiactivated'
}

const POST_CALLBACKS = {
  active: 'afterActivated',
  inactive: 'afterDiactivated',
  completed: 'afterCompleted',
}

const TRANSITION_STATES = {
  activate: 'active',
  complete: 'completed'
}

const HEADER_HEIGHT = 90

export const EVENTS = {
  activate: 'activate.accordion-step',
  complete: 'complete.accordion-step'
}

export class AccordionFlowStep {
  constructor(container, index) {
    this.container = container
    this.trigger = this.container.trigger.bind(this.container)
    this.index = index
    this.currentState = this.container.is('.is-active') ? 'active' : 'inactive'
    this.name = this.container.data('accordionStep')
    this.path = this.container.data('accordionStepPath')
    this.setStateClass('inactive')

    Object.keys(TRANSITION_STATES).forEach((state) => {
      this.container.on('click', `.js-accordion-step__${state}`, () => { this.state = state; return false })
    })
  }

  activate() {
    this.changePageTitle();
    this.container.find(ACCORDION_FLOW_STEP_BODY).collapse('show')
    if (this.index > 0) scrollToUnlessVisible(this.container)
  }

  close() {
    this.container.find(ACCORDION_FLOW_STEP_BODY).collapse('hide')
  }

  beforeStateChanged(state) { return true }

  completeStep() { this.state = 'complete' }
  canComplete() { return true }
  onActivated(stateWas) {}
  afterActivated() {}
  afterDiactivated() {}
  afterCompleted() {}
  onCompleted(stateWas) {}
  onDiactivated(stateWas) {}

  beforeComplete() {
    const canComplete = this.canComplete()
    if (canComplete.then) return canComplete

    return canComplete ? (new $.Deferred).resolve() : (new $.Deferred).reject()
  }

  get isActive() {
    return this.currentState === 'active'
  }

  set state(state) {
    const transitionState = TRANSITION_STATES[state] ? state : null
    if (transitionState) state = TRANSITION_STATES[state]

    if (state === this.currentState) return

    const stateChanged = state === 'completed' ? this.beforeComplete() : new $.Deferred().resolve()
    const stateWas = this.currentState

    stateChanged.then(this.changeState.bind(this, state, stateWas, transitionState))
  }

  changeState(state, stateWas, transitionState) {
    this.currentState = state
    this.setStateClass(stateWas)

    if (this.currentState === 'completed' || this.currentState === 'inactive') this.close()
    if (this.currentState === 'active') this.activate()

    if (transitionState) this.container.trigger(EVENTS[transitionState], [this])
  }

  setStateClass(state) {
    this[CALLBACKS[this.currentState]].apply(this, [state])
    this.container.removeClass('is-inactive is-active is-completed')
    this.container.addClass(`is-${this.currentState}`)
    this[POST_CALLBACKS[this.currentState]].apply(this, [state])
  }

  changePageTitle() {
    if(window.location.href.includes('gifts')) {
      let step = this.container.data('accordionStepPath')
      document.title = `Give Purple Carrot ${step.charAt(0).toUpperCase() + step.slice(1)} | Purple Carrot`
    }
    else {
      let step = this.container.data('accordionStep')
      document.title = `Account Creation ${step.charAt(0).toUpperCase() + step.slice(1)} Step | Purple Carrot`
    }
  }
}
