import EditExtras from './edit_extras'

const CONFIRMATION_MODAL = '.js-plan-and-meal-preferences-confirmation'
const MODALS_TO_SHOW_CONFIRMATION = '.js-meal-preferences-modal,.js-modal-edit-extras'

export default class EditMealPreferences {
  constructor(mealPreferencesModal) {
    this.editExtras = new EditExtras($('.js-modal-edit-extras'))
    this.bind()
  }

  bind() {
    $(document).on('hidden.bs.modal', MODALS_TO_SHOW_CONFIRMATION, this._showConfirmationModal)
  }

  _showConfirmationModal(event) {
    if ($(event.currentTarget).data('form-persisted')) return
    $(CONFIRMATION_MODAL).modal('show')
  }
}

