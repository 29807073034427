const MEAL_PREFERENCE_CARD = '.js-modal-meal-preferences-new__card';
const MEAL_PREF_CARD_ICON = '.js-modal-meal-preference-success-icon';
class MealPreferenceSelect {
  constructor(container) {
    this.container = container;
    this._bind();
  }

  _bind() {
    this.container.on('click keydown', MEAL_PREFERENCE_CARD, this._selectCard.bind(this));
  }

  _selectCard(event) {
    if (event.type == 'click' || (event.key == 'Enter' || event.key == ' ')) {
      const previousSelected = this.container.find('.active_card');
      previousSelected.find(MEAL_PREF_CARD_ICON).addClass('d-none');
      previousSelected.removeClass('active_card');

      event.currentTarget.classList.add('active_card');
      event.currentTarget.querySelector('.js-modal-meal-preference-success-icon').classList.remove('d-none');

      const plan_name = $(event.currentTarget).data('plan-group');
      this.container.find('.js-modal-meal-preferences_plan_name')[0].value = plan_name;
    }
  }
}

$(() => {
  const container = $('.js-meal-preferences-modal-new');
  if (container.length) {
    new MealPreferenceSelect(container);
  }
});
