import { addParameterToURL } from 'javascripts/consumer/utils/navigation'
import { showDefaultCoupon } from 'javascripts/consumer/components/sign_up_modal'

const QUIZ_STEPS = '.js-quiz__step'
const QUIZ_STEP_BACK = '.js-quiz__step-back'
const QUIZ_ANSWER = '.js-quiz__answer'
const QUIZ_COMPLETE = '.js-quiz__complete'
const SIGNUP_FORM = '.js-quiz__complete .js-signup-form'
const OMNNIAUTH_LINKS = '.js-quiz__complete .js-omniauth-button'

export default class InteractiveQuizSteps {
  constructor(container) {
    this.container = container
    this.steps = this.container.find(QUIZ_STEPS)

    this._bind()
    heap.track('Interactive Quiz Opened', {})

    if (history.state) {
      this.history = history.state.history
      this.answers = history.state.answers
      this._renderStep(history.state.step, false)
    } else {
      this.history = []
      this.answers = {}
      this._renderStep(1, false)
    }

    this._showDefaultCoupon()
    this.container.modal('show')
  }

  _bind() {
    this.container.on('click', QUIZ_ANSWER, this._nextStep.bind(this))
    this.container.on('click', QUIZ_STEP_BACK, this._stepBack.bind(this))
    this.container.on('ajax:beforeSend', QUIZ_COMPLETE, this._setSignUpPlan.bind(this))
    this.container.on('signup:form_updated', SIGNUP_FORM, this._showDefaultCoupon.bind(this))
    this.container.on('click', OMNNIAUTH_LINKS, this._addPlanToOmniauth.bind(this))
  }

  _nextStep(event) {
    event.preventDefault()
    const target = $(event.currentTarget)

    if (this.activeStep) {
      history.replaceState({ answers: this.answers, step: this.activeStep, history: this.history }, document.title)
      this.history.push([this.activeStep, this.answers])
    }

    this.answers = this._assembleAnswer(target.data())

    this._renderStep(target.data('next-step'))
  }

  _stepBack() {
    if (!this._isStepBack) return

    const [step, answers] = this.history.pop()

    this.answers = answers
    this._renderStep(step)
  }

  _renderStep(step, focus = true) {
    const nextStep = $(this.steps.filter(`[data-step="${step}"]`)[0])

    if (nextStep.data('redirect-to')) {
      const buttonToForm = $(nextStep.data('redirect-to')).closest('form')
      buttonToForm.attr('action', addParameterToURL(buttonToForm.attr('action'), this._selectedPlanParams))
      buttonToForm.submit()

      return
    }

    this.activeStep = nextStep.data('step')
    this.container.find(QUIZ_STEP_BACK).toggleClass('d-none', !this._isStepBack)

    this.steps.addClass('d-none')
    nextStep.removeClass('d-none')

    if(focus) {
      nextStep.focus()
    }
  }

  _assembleAnswer({plan, servings, spend}) {
    plan = plan || this.answers.plan
    servings = servings || this.answers.servings

    if (!plan && spend) plan = this._planOnSpendAndServings(spend, servings)

    return { plan: plan, servings: servings, spend: spend }
  }

  _planOnSpendAndServings(spend, servings) {
    if (spend === 'less') return 'quick_easy'
    if (servings === '1-2+') return 'four_servings'

    return 'chefs_choice'
  }

  _setSignUpPlan(event) {
    let [_xhr, settings] = event.detail;
    settings.data = `${settings.data}&${this._selectedPlanParams}`
  }

  _showDefaultCoupon() {
    showDefaultCoupon();
  }

  _addPlanToOmniauth(event) {
    const target = $(event.currentTarget)
    target.attr('href', `${target.attr('href')}?quiz_plan_recommendation=${this.answers.plan}`)
  }

  get _isStepBack() {
    return this.history.length > 0
  }

  get _selectedPlanParams() {
    return `plan=${this.answers.plan}&quiz_plan_recommendation=${this.answers.plan}`
  }
}
