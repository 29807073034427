export default class TogglePanel {
  constructor(container) {
    this.container = container
    this.bind()
  }

  bind() {
    this.container.on('click', '.js-toggle-panel__link', this.handleActivateClick.bind(this))
  }

  handleActivateClick(event) {
    const activePanel = $(event.currentTarget)
    if (activePanel.attr('href')) event.preventDefault()

    this.activatePanel(activePanel)
  }

  activatePanel(activePanel) {
    this.container.find('.js-toggle-panel__link').each((index, element) => {
      element = $(element)
      if (!activePanel) activePanel = element

      this.container.find(element.data('target') || element.attr('href')).removeClass('is-active')
    })

    $(activePanel.data('target') || activePanel.attr('href')).addClass('is-active')
  }
}
