// Images
require.context('../images/consumer', true)
require.context('../images/styleguide', true)

// STYLEGUIDE STYLES
import '../stylesheets/consumer'

// STYLEGUIDE JS
import 'javascripts/styleguide/tpc'

import Rails from "@rails/ujs"

// CUSTOM JS FOR CONSUMER APP

//HEAP ANALYITICS
import 'javascripts/consumer/components/ab_tests/vwo_runner'
import 'javascripts/consumer/components/analytics/heap_identity'
import 'javascripts/consumer/components/analytics/heap_vwo'
import 'javascripts/consumer/components/analytics/heap_plantry_list_viewed'

//SHARED COMPONENTS
import 'javascripts/consumer/components/animated_section'
import 'javascripts/consumer/components/trackable'
import 'javascripts/consumer/components/bootstrap_modal'
import 'javascripts/consumer/components/bootstrap_ios_modal'
import 'javascripts/consumer/components/nav_offcanvas'
import 'javascripts/consumer/components/accordion_flow'
import 'javascripts/consumer/components/auto_complete.js'
import 'javascripts/consumer/components/address_autocomplete.js'
import 'javascripts/consumer/components/js_toggle'
import 'javascripts/consumer/components/sign_up_modal'
import 'javascripts/consumer/components/signup/preferences'
import 'javascripts/consumer/components/welcome_survey'
import 'javascripts/consumer/components/load_more'
import 'javascripts/consumer/components/sticky'
import 'javascripts/consumer/components/alerts_and_validations_focus'
import 'javascripts/consumer/components/plan_select'
import 'javascripts/consumer/components/meal_preference_select'
import 'javascripts/consumer/components/expandable_popover'
import 'javascripts/consumer/components/bootstrap_tabs_navigation'
import 'javascripts/consumer/components/chatbot_widget';
import 'javascripts/consumer/components/recipes_gallery';
import 'javascripts/consumer/components/recipe';
import 'javascripts/consumer/components/friendbuy'

// Disable iOS zoom on focus behavior for inputs
import('javascripts/consumer/components/disable_ios_autozoom');

import BraintreeClient from 'javascripts/consumer/components/braintree_client'
import { onYouTubeIframeAPIReady } from 'javascripts/consumer/utils/youtube_loader'
import { recaptchaOnloadCallback, invisibleRecaptchaSubmit } from 'javascripts/consumer/components/recaptcha'
import { onGooglePlacesApiLoaded } from 'javascripts/consumer/components/google_places_api.js'
import { onGooglePayLoaded } from 'javascripts/consumer/components/google_pay_button.js'

import 'javascripts/data_fragmentable'

//SIGNUP
import 'javascripts/consumer/components/signup/step'

//MKTG PAGES
import 'javascripts/consumer/pages/plans'
import 'javascripts/consumer/pages/contact'
import 'javascripts/consumer/pages/coupons'

//RECIPE PREFERENCES
import 'javascripts/consumer/components/recipe_preferences/edit_recipe_preferences'

//GIFTS
import 'javascripts/consumer/components/gifts/step'

//RECIPES
import 'javascripts/consumer/components/recipes/navigation'
import 'javascripts/consumer/components/recipes/video_section'

//ACCOUNT
import 'javascripts/consumer/components/manage_subscription'
import 'javascripts/consumer/pages/giveaways'
import 'javascripts/consumer/pages/profiles'

//SURVEYS
import 'javascripts/consumer/pages/surveys/nps_survey'
import 'javascripts/consumer/pages/surveys/ces_survey'
import 'javascripts/consumer/pages/surveys/recipe_feedback_survey'

//SKUS
import 'javascripts/consumer/pages/skus'

//Plugins
import iframeModal from 'javascripts/plugins/iframeModal'
import 'javascripts/plugins/webviewBtns'

//SCRIPTS
window.$ = require('jquery')
Rails.start();
iframeModal(window.$);
window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady
window.recaptchaOnloadCallback = recaptchaOnloadCallback
window.invisibleRecaptchaSubmit = invisibleRecaptchaSubmit
window.onGooglePlacesApiLoaded = onGooglePlacesApiLoaded
window.onGooglePayLoaded = onGooglePayLoaded
window.BraintreeClient = BraintreeClient
