export default class InputValidation {
  constructor(input, validations) {
    this.input = input
    this.validations = validations
    this.wrapper = this.input.closest('.js-form-group')
  }

  isValid() {
    const isValid = this.validations.every((validation) => {
      const message = validation.apply(this, [this.input])
      if (!message) return true

      this.wrapper.addClass('is-invalid')
      this.messageContainer.html(message)
    })

    if (isValid) this.wrapper.removeClass('is-invalid')
    return isValid
  }

  get messageContainer() {
    return this.inputMessageContatiner = this.inputMessageContatiner || this.addMessageContainer()
  }

  addMessageContainer() {
    const messageContainer = $('<div class="c-form-group__invalid-text js-form-group__invalid-text"></div>')
    this.wrapper.append(messageContainer)

    return messageContainer
  }
}
